import { DenticareEnvi } from '@environments/interfaces/denticare.interface';

export const environment: DenticareEnvi = {
  production: false,
  api: 'https://dev-api.payplanadvantage.com/pa/v2',
  authBaseURL: 'https://dev-api.payplanadvantage.com/paauth/v2',
  basiq: {
    apiBaseUrl: 'https://dev-api.payplanadvantage.com/basiq/v1/api',
    consentUrl: 'https://consent.basiq.io/home'
  },
  apimKey: '59fd084c859846c19706f6fa32cf16fb',
  client: 'DentiCare',
  company_brand: 'DENTICARE',
  brand_provider_relations_email: 'providerrelations@denticarepayplans.com.au',
  stepperPointer: true,
  pbKey: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDhDCTGGgv28zTBhPNa3n/3yQzL
  oCoC7Ketg/L38dcOeKT0J4Fgzovk/oUEZ6d3fGYeckgJ64FXZS4uBGzLgZLmlH+C
  eCLr6iC6Osol+LTgE6Ft3HRzX0l+AV+GYJF1+QOoH32h1T66cjF5NI78TwuYSdHq
  fEorMOV9OKBGcEht5QIDAQAB
  -----END PUBLIC KEY-----`,
  payload_encrypt: 'true',
  dateFormat: 'dd/MM/yyyy',
  dateFormatMaterial: 'DD/MM/YYYY',
  itemPerPage: 10,
  rules_json_url: 'https://dev-api.payplanadvantage.com/rules',
  rules_json: {
    global: {
      offline_mode: true,
      maintenance_mode: false
    },
    product: {
      mobile: {
        denticare: {
          offline_mode: false,
          android_min_build: '18',
          android_min_version: '1.1.2',
          ios_min_version: '1.2',
          ios_min_build: '53'
        }
      },
      portal: {
        denticare: {
          offline_mode: true,
          maintenance_mode: false,
          maintenance_message:
            'Your portal is currently undergoing a system upgrade to ensure you have access to the best possible experience. The upgrade is expected to continue for the next 24 hours at most. We expect your access to be reinstated on ????. <br>—Thank you for your understanding',
          clear_cache_datetime: 1630575248188
        },
        transacty: {
          offline_mode: false,
          maintenance_mode: false,
          maintenance_message:
            'Your portal is currently undergoing a system upgrade to ensure you have access to the best possible experience. The upgrade is expected to continue for the next 24 hours at most. We expect your access to be reinstated on ????. <br>—Thank you for your understanding',
          clear_cache_datetime: 1630575248188
        }
      }
    }
  },
  harmonyBaseURL: 'https://hosted.mastersoftgroup.com/harmony/rest/AU/address',
  defaultCountry: 'AU',
  OTPType: 1,
  OTPTranType: 'customrecord_ddr_in_progress',
  modules: {
    general: {
      shared: {
        input: {
          autocomplete: {
            enabled: true
          }
        }
      },
      treatmentType: {
        show: true
      }
    },
    payments: {
      smsJourney: {
        successScreen: {
          rpAppDetails: {
            showRpAppLink: true,
            playStoreLink:
              'https://play.google.com/store/apps/details?id=com.payvantageglobal.dcpatient',
            appStoreLink: 'https://apps.apple.com/au/app/denticare/id1534707515'
          }
        },
        skipBsbValidation: true
      },
      createPlanJourney: {}
    },
    marketingCenter: {
      showWebsiteBadgeDownloadBtn: false
    }
  },
  files: {
    maxSize: 10000000 // 10MB
  },
  defaultCurrency: 'en-AU'
};
