import { RelativeFirstPaymentOption } from "../enums/rp-fpd.enum";

export const UpdatePlanFieldCodes = {
  COMMENTS: 'COMMENTS',
  PIF_PAYMENT_STATUS: 'PIF_PAYMENT_COLLECTED_DETAILS',
  PATIENT_ID: 'PRAC_PATIENT_ID_NO'
};

export const RelativeFirstPaymentDateOrder = {
  [RelativeFirstPaymentOption.Days]: 1,
  [RelativeFirstPaymentOption.Weeks]: 2,
  [RelativeFirstPaymentOption.Months]: 3
};
