import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { MissedPaymentDetailsService } from './missed-payments-details.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { NotificationService } from '@app/core/services/notification.service';
import { concatMap, map, take, takeUntil } from 'rxjs/operators';
import { PaymentMissedItemResponse } from '@app/core/models';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentMissedDetailsItemResponse } from '@app/core/models/payment/payment-missed-details-item-response';
import { PaymentMissedDetailsPaymentHistoryItemResponse } from '@app/core/models/payment/payment-missed-details-payment-history-item-response';
import { HelperService } from '@app/core/services/helper.service';
import { SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { MissedPaymentsService } from '../services/missed-payments.service';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sliqpay-missed-payments-details',
  templateUrl: './missed-payments-details.component.html',
  styleUrls: ['./missed-payments-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissedPaymentsDetailsComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'missed_payment_details';

  ProviderConfigFeature = ProviderConfigFeature;

  itemPerPage = environment.itemPerPage;
  page = 0;

  plan?: PaymentMissedItemResponse;
  ddrID = '';
  isMissedPayment = 'false';
  showRedTotalOverDueAmount = false;
  showRedCurrentMissedPayments = false;
  mpPlanDetails!: PaymentMissedDetailsItemResponse;
  mpDetails$ = new BehaviorSubject<PaymentMissedDetailsItemResponse[] | null>(null);
  mpDetailsPaymentHistoryFiltered$ = new BehaviorSubject<
    PaymentMissedDetailsPaymentHistoryItemResponse[] | null
  >(null);

  searchWidgetConfig$!: Observable<SearchConfig[]>;
  sendInvoiceBtnConfig$!: Observable<AppButton>;
  escalateBtnConfig$!: Observable<AppButton>;

  unsubscribe$ = new Subject<boolean>();
  searchData: SearchRequest = {};

  private subs = new Subscription();

  constructor(
    public userSettingsService: UserSettingsService,
    private notificationService: NotificationService,
    private service: MissedPaymentDetailsService,
    private helperService: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private missedPaymentsService: MissedPaymentsService,
    private modal: NgbModal
  ) {
    this.subs.add(
      this.activatedRoute.params.subscribe(params => {
        this.isMissedPayment = params['isMissedPayment'];
        this.ddrID = params['ddrID'];

        this.navigationBackRoute();
      })
    );
  }

  ngOnInit(): void {
    this.setConfig();

    if (this.ddrID != undefined) {
      this.getPaymentMissedDetails(this.ddrID.replace('DDR-', ''));
    } else {
      this.router.navigate([this.navigationBackRoute()]);
    }

    this.missedPaymentsService
      .getMissedPaymentByDdrId$(this.activatedRoute.snapshot.params['ddrID'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(ddr => {
        if (ddr) {
          this.plan = ddr;
        }
      });
  }

  navigationBackRoute() {
    if (this.isMissedPayment == 'true') {
      return 'payment-plan-summary/missed-payments/list';
    } else {
      return 'payment-plan-summary';
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPaymentMissedDetails(id: string) {
    this.subs.add(
      this.service.getPaymentMissedDetails(id).subscribe(response => {
        if (response) {
          const missedPaymentDetails = response.data;
          this.mpDetails$.next(missedPaymentDetails);
          if (this.mpDetails$.value != null) {
            this.mpPlanDetails = this.mpDetails$.value[0];
            this.showRedTotalOverDueAmount =
              Number(this.mpPlanDetails.totalOverDueAmount) > 0 ? true : false;
            this.showRedCurrentMissedPayments =
              Number(this.mpPlanDetails.currentMissedPayments) > 0 ? true : false;
            this.mpDetailsPaymentHistoryFiltered$.next(this.mpPlanDetails.history);
          }
        }
      })
    );
  }

  sendInvoice(): void {
    this.openSendInvoiceConfirmationDialog()
      .pipe(
        concatMap((proceed: boolean) => {
          if (proceed && this.plan) {
            return this.missedPaymentsService
              .sendInvoice$(this.plan['DDR Internal ID'])
              .pipe(map(res => this.helperService.checkAPIResponse(res)));
          }
          return of(false);
        }),
        take(1)
      )
      .subscribe(isSent => {
        if (isSent) {
          const successMsg =
            'An invoice of the total overdue amount has been sent via SMS to the Responsible Party.';
          this.notificationService.success(successMsg, 'Invoice Sent');
          this.router.navigate([this.navigationBackRoute()]);
        }
      });
  }

  escalate(): void {
    this.openEscalateConfirmationDialog()
      .pipe(
        concatMap(isConfirmed => {
          if (isConfirmed && this.plan) {
            const ddrId = this.plan['DDR'];
            return this.missedPaymentsService.escalateMissedPayment$(this.plan).pipe(
              map(() => {
                const successMsg = 'Payment ' + ddrId + ' has been escalated to APCA';
                this.notificationService.success(successMsg, 'Escalated');
              })
            );
          }
          return of(false);
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  showSendInvoice(): boolean {
    return (
      this.userSettingsService.isPromptPayEnabled() === true &&
      Boolean(this.plan?.['Escalate to Collection Agency']) === false &&
      (this.plan?.['Invoice SMS Counter'] == '' ||
        Number(this.plan?.['Invoice SMS Counter']) <
          Number(this.userSettingsService.getPromptPaySmsLimit()))
    );
  }

  onSort({ column, direction, type }: any) {
    this.mpDetailsPaymentHistoryFiltered$.next(
      this.helperService.onSort(
        column,
        direction,
        this.mpDetailsPaymentHistoryFiltered$?.value,
        type
      )
    );
  }

  onSearchValueChange(event: SearchRequest): void {
    this.searchData = event;
    // reset focus to the first page
    this.page = 1;
  }

  private openSendInvoiceConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Send Invoice',
      content: 'send_invoice_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'SMS Invoice',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map(proceedWithInvoice => proceedWithInvoice));
  }

  private openEscalateConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Esacalate To Agency',
      content:
        parseFloat(this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee) > 0
          ? 'escalate_with_fees_message'
          : 'escalate_without_fees_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      strReplacements: [this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee],
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'Transfer to APCA',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map(isConfirmed => isConfirmed));
  }

  private setConfig(): void {
    this.sendInvoiceBtnConfig$ = this.service.getSendInvoiceBtnConfig();
    this.escalateBtnConfig$ = this.service.getEscalateBtnConfig();

    this.searchWidgetConfig$ = combineLatest([
      this.service.getSearchWidgetConfig(),
      this.mpDetailsPaymentHistoryFiltered$
    ]).pipe(
      map(([rawConfigs, paymentPlans]) => {
        return rawConfigs.map(config => {
          const cloneConfig: SearchConfig = { ...config };

          if (config.name === 'paymentStatus') {
            cloneConfig.config = {
              ...cloneConfig.config,
              options: this.helperService.getUniqueObjectValuesFromArray(
                paymentPlans || [],
                'paymentStatus'
              )
            };
          }

          return cloneConfig;
        });
      })
    );
  }
}
