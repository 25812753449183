import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { TermsAndConditionsDataService } from '@app/core/data-services/terms-and-conditions/terms-and-conditions-data.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SettingsTncService } from './settings-tnc.service';
@Component({
  selector: 'sliqpay-settings-tnc',
  templateUrl: './settings-tnc.component.html',
  styleUrls: ['./settings-tnc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTncComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'settings_tnc';
  data$!: Observable<any>;

  unsubscribe$ = new Subject();

  downloadBtnConfig$!: Observable<AppButton>;

  constructor(
    protected helperService: HelperService,
    protected settingsTncService: SettingsTncService,
    private tncDataService: TermsAndConditionsDataService
  ) {}

  ngOnInit(): void {
    this.setConfig();
    this.data$ = this.setTncPDF();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  downloadTncPDF() {
    this.data$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(data => {
          const linkSource = `data:application/pdf;base64,${data?.pdfInBase64}`;
          const downloadLink = document.createElement('a');
          const fileName = data?.fileName + '.pdf';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
      )
      .subscribe();
  }

  private setTncPDF(): Observable<any> {
    return this.tncDataService.getSignedTncPdf().pipe(
      takeUntil(this.unsubscribe$),
      switchMap(response => {
        const pdfInBase64 = response?.data[0].custrecord_tnc_pdf_content;

        const byteArray = new Uint8Array(
          atob(pdfInBase64)
            .split('')
            .map(char => char.charCodeAt(0))
        );

        return of({
          pdfInBase64,
          pdfInUint8Array: byteArray,
          fileName: response?.data[0].name + '.pdf'
        });
      })
    );
  }

  private setConfig(): void {
    this.downloadBtnConfig$ = this.settingsTncService.getDownloadBtnConfig();
  }
}
