import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { FullscreenLoaderService } from './core/components/fullscreen-loader/fullscreen-loader.service';
import { ConfigService } from './core/services/config/config.service';
import { LangService } from './core/services/lang.service';

@Component({
  selector: 'sliqpay-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DentiCare front';
  windowWidth$ = new BehaviorSubject(1280);
  wrapperClass$ = new BehaviorSubject(null);
  fullscreenLoaderState$ = this.fullscreenLoaderService.fullscreenUiState$();

  config$!: Observable<any>;
  lang$!: Observable<any>;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private langService: LangService,
    private fullscreenLoaderService: FullscreenLoaderService
  ) {
    window.onbeforeunload = () => {
      sessionStorage.removeItem('resumePlan');
    };
  }

  ngOnInit() {
    // Setup config
    this.config$ = this.configService.setConfigInStore();
    this.lang$ = this.langService.setLangConfigInStore();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data.wrapperClass) {
          this.wrapperClass$.next(data.wrapperClass);
        }
      });
  }

  onResize(event: any) {
    this.windowWidth$.next(event.target.innerWidth);
  }
}
