<ng-template [ngIf]="progress && progress.length === 0" [ngIfElse]="progressBar">
  <span>N/A</span>
</ng-template>

<ng-template #progressBar>
  <div class="wrapper">
    <div
      class="progress-label"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (mouseenter)="menuTrigger.openMenu()"
      (mouseleave)="menuTrigger.closeMenu()"
    >
      <label>{{ this.getFirstUncompletedStepLabel() }}</label>
    </div>

    <div class="d-flex">
      <ng-container *ngFor="let step of progress">
        <div class="stepProgressBar" [class.completedStep]="step.custrecord_spps_completed"></div>
      </ng-container>
    </div>
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="cust-menu" [hasBackdrop]="false">
  <div class="step-wrapper">
    <ng-container *ngFor="let step of progress">
      <div class="item">
        <ng-container *ngIf="step.custrecord_spps_completed; else unCompletedIcon">
          <i *ngIf="step.custrecord_spps_completed" class="uil uil-check"></i>
        </ng-container>
        <ng-template #unCompletedIcon>
          <i
            *ngIf="isCurrentUncompletedStep(step.custrecord_spps_step_no)"
            class="icon-circle-current"
          ></i>
        </ng-template>

        <label
          [class.not-completed]="
            !step.custrecord_spps_completed &&
            !isCurrentUncompletedStep(step.custrecord_spps_step_no)
          "
          [class.current-step-label]="isCurrentUncompletedStep(step.custrecord_spps_step_no)"
          >{{ step.custrecord_spps_completed_label }}</label
        >
      </div>
    </ng-container>
  </div>
</mat-menu>
