<div class="modal-container">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <h4>{{ data?.title }}</h4>
  <pdf-viewer [src]="data?.pdfInUint8Array" [render-text]="true"></pdf-viewer>
</div>
