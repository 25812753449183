import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { addDays, format } from 'date-fns';
import parse from 'date-fns/parse';
// @ts-ignore
import { Crypt } from 'hybrid-crypto-js';
import * as _moment from 'moment';
import { SmsJourneyPlan } from '../models';
import { FormFieldsService } from './form-fields/form-fields.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  objFormFields = this.getObjFormFields();
  objUser = this.getObjUser();

  activeDropDownFalseId: string | undefined;
  activeDropDownTrueId: string | undefined;
  pbKey = environment.pbKey;
  payload_encrypt = environment.payload_encrypt;

  constructor(private _location: Location, private formFieldsService: FormFieldsService) {}

  formatMoney(value: any): string {
    return parseFloat(value).toFixed(2);
  }

  toFixedNumber(num: number, digits: number, base?: number): number {
    const pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  }

  parseDate(date: string, currentFormat: string): Date {
    return parse(date, currentFormat, new Date());
  }

  aesDecode(value: any) {
    value = JSON.parse(value);

    const key = CryptoJS.enc.Base64.parse(value.key);
    const iv = CryptoJS.enc.Base64.parse(value.iv);
    const decrypted = CryptoJS.AES.decrypt(value.payload, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }

  aesEncode(value: string) {
    const secret = this.aesGenerateKeyIV();

    const key = CryptoJS.enc.Base64.parse(secret.kyAES);
    // @ts-ignore
    const iv = CryptoJS.enc.Base64.parse(secret.ivAES);

    const encrypted = CryptoJS.AES.encrypt(value, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    const crypt = new Crypt();

    const kyAES = JSON.parse(crypt.encrypt(this.pbKey, secret.kyAES));
    const ivAES = JSON.parse(crypt.encrypt(this.pbKey, secret.ivAES));

    kyAES.v = '';
    ivAES.v = '';

    return {
      key: kyAES,
      iv: ivAES,
      payload: encrypted.toString(),
      payload_encrypt: this.payload_encrypt
    };
  }

  // @ts-ignore
  aesGenerateKeyIV(): { kyAES: string; ivAES: string } {
    try {
      const randKey = this.makeRandCharacter(16);
      const randIV = this.makeRandCharacter(16);

      const randIVBase64 = btoa(randIV);

      const randKeyHash = CryptoJS.SHA256(randKey).toString(CryptoJS.enc.Base64);

      return {
        kyAES: randKeyHash,
        ivAES: randIVBase64
      };
    } catch (error) {
      console.log(error);
    }
  }

  makeRandCharacter(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  checkSessionItemIfExist(sessionItem: string) {
    return (
      this.isJson(sessionStorage.getItem(sessionItem) || '') &&
      sessionStorage.getItem(sessionItem) !== 'undefined' &&
      sessionStorage.getItem(sessionItem) !== null &&
      sessionStorage.getItem(sessionItem) !== ''
    );
  }

  checkValidUser() {
    return (
      this.isJson(sessionStorage.getItem('user') || '') &&
      sessionStorage.getItem('user') !== 'undefined' &&
      sessionStorage.getItem('user') !== null &&
      sessionStorage.getItem('user') !== ''
    );
  }

  getObjFormFields() {
    return JSON.parse(sessionStorage.getItem('form_fields') as string);
  }

  getObjUser() {
    return JSON.parse(sessionStorage.getItem('user') as string);
  }

  getObjectByValue(array: any[], key: string, value: any) {
    return array.find(arr => arr[key] == value);
  }

  getDoctorID(val: any) {
    return this.getObjectByValue(
      this.getObjFormFields()['custrecord_custentity_providercontactdr'],
      'internalid',
      val
    )
      ? this.getObjectByValue(
          this.getObjFormFields()['custrecord_custentity_providercontactdr'],
          'internalid',
          val
        ).custentity_custentity_contact_drprovider
      : '';
  }

  getPaymentFrequencyName(val: any, toLowerCase?: boolean) {
    const text = this.getObjectByValue(
      this.getObjFormFields()['custrecord_mfa_ddr_payment_frequency'],
      'internalid',
      val
    )
      ? this.getObjectByValue(
          this.getObjFormFields()['custrecord_mfa_ddr_payment_frequency'],
          'internalid',
          val
        ).name
      : '';

    return toLowerCase ? text.toLowerCase() : text;
  }

  getObjectFieldValueByFieldNameAndFieldValue(
    array: any[],
    targetFieldName: string,
    targetFieldValue: any,
    expectingFieldName: string | number
  ) {
    const matchObject = this.getObjectByValue(array, targetFieldName, targetFieldValue);

    if (matchObject) {
      return matchObject[expectingFieldName];
    } else {
      return '';
    }
  }

  checkAPIResponse(data: { isError: string }) {
    return data.isError === '0';
  }

  getAPIDataResponse(data: { data: any }) {
    return data.data;
  }

  onSort(
    column: string,
    direction: string,
    data: any[] | null,
    type = 'string',
    options?: { dateTimeFormat: string }
  ) {
    if (!direction || !data) {
      return data;
    }

    return this.sort(data, column, direction, type, options);
  }

  jsonParse(element: any, key: string) {
    return JSON.parse(element)[key];
  }

  transformToCurrency(event: any, firstStepGroup: UntypedFormGroup, options: any | null = null) {
    const fcName = event?.target.attributes[2].value;
    if (event.target.value) {
      const value = firstStepGroup.get(fcName)?.value;
      if (typeof value === 'number') {
        firstStepGroup.get(fcName)?.setValue(this.formatMoney(value));
      } else {
        firstStepGroup.get(fcName)?.setValue(this.formatMoney('0.0'));
      }
    } else {
      // @ts-ignore
      if (options != null && options.defaultValue === false) {
      } else {
        firstStepGroup.get(fcName)?.setValue(this.formatMoney('0.0'));
      }
    }
  }

  transformToCurrencyByInput(
    name: string,
    firstStepGroup: UntypedFormGroup,
    options: any | null = null
  ) {
    if (firstStepGroup.get(name)?.value) {
      const newValue = firstStepGroup.get(name)?.value;

      if (parseInt(newValue, 10)) {
        firstStepGroup.get(name)?.setValue(this.formatMoney(newValue));
      } else {
        firstStepGroup.get(name)?.setValue(this.formatMoney('0.0'));
      }
    } else {
      if (options != null && options.defaultValue === false) {
      } else {
        firstStepGroup.get(name)?.setValue(this.formatMoney('0.0'));
      }
    }
  }

  getEmploymentStatusById(val: string | number) {
    return this.getObjectByValue(
      this.getObjFormFields()['custrecord_pa_custfield_ddr_emplstatus_special'],
      'internalid',
      val
    )
      ? this.getObjectByValue(
          this.getObjFormFields()['custrecord_pa_custfield_ddr_emplstatus_special'],
          'internalid',
          val
        ).name
      : '';
  }

  strtolower(input: string) {
    return input.toLowerCase();
  }

  invalidEmploymentStatus(empStatus: string | number) {
    return (
      this.getEmploymentStatusById(empStatus) === '' ||
      empStatus === 4 ||
      this.getEmploymentStatusById(empStatus) === 'Self-Funded Retiree' ||
      empStatus === 9 ||
      this.getEmploymentStatusById(empStatus) === 'Unemployed' ||
      empStatus === 10 ||
      this.getEmploymentStatusById(empStatus) === 'Casual'
    );
  }

  dateFormat(data: Date): NgbDateStruct {
    return {
      year: new Date(data).getFullYear(),
      month: new Date(data).getMonth() + 1,
      day: new Date(data).getDate()
    };
  }

  getPlanTypeName(val: any) {
    val = val + '';
    return this.getObjectByValue(
      this.getObjFormFields()['custrecord_mfa_ddr_plan_type'],
      'internalid',
      val
    )
      ? this.getObjectByValue(
          this.getObjFormFields()['custrecord_mfa_ddr_plan_type'],
          'internalid',
          val
        ).custrecord_ddrtype_label
      : '';
  }

  updateKeyValue(value: any, item: any) {
    const prevData = JSON.parse(sessionStorage.getItem(item) || '');
    Object.keys(value).forEach(val => {
      prevData[val] = value[val];
    });

    sessionStorage.setItem(item, JSON.stringify(prevData));
  }

  updateArrayByKeyValuePairs<T, K extends keyof T>(
    updateInfo: {
      propertiesToBeUpdated: Array<{ propNameToUpdate: K; newPropValue: T[K] }>;
      keyToUpdate: keyof T;
      valueToUpdate: T[keyof T];
    },

    arr: T[]
  ): T[] {
    const { propertiesToBeUpdated, keyToUpdate, valueToUpdate } = updateInfo;

    return arr.map(item => {
      if (item[keyToUpdate] === valueToUpdate) {
        let updatedItem = { ...item };
        for (const update of propertiesToBeUpdated) {
          const { propNameToUpdate, newPropValue } = update;
          updatedItem = { ...updatedItem, [propNameToUpdate]: newPropValue };
        }
        return updatedItem;
      }
      return item;
    });
  }

  disableDecimalInput(event: any) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  clearFormGroup(formGroup: UntypedFormGroup, listOfFields: any) {
    listOfFields.forEach((element: any) => {
      formGroup.get(element)?.setValue('');
    });
  }

  returnInternalIdPerIdType(idType: string): string {
    if (idType === 'DRIVERS_LICENSE' || idType === '3') {
      return '3';
    } else if (idType === 'PASSPORT' || idType === '2') {
      return '2';
    } else if (idType === 'MEDICARE' || idType === '1') {
      return '1';
    } else if (idType === 'NONE' || idType === '7') {
      return '7';
    }
    return '';
  }

  clearAllValidators(arrList: string[], formGroup: UntypedFormGroup) {
    arrList.forEach(controlName => {
      formGroup.controls[controlName].clearValidators();
      formGroup.controls[controlName].updateValueAndValidity();
    });
  }

  updateValidators(arrList: string[], formGroup: UntypedFormGroup) {
    arrList.forEach(controlName => {
      formGroup.controls[controlName].updateValueAndValidity();
    });
  }

  // for KYC payload
  returnValueSourceType(idType: string): string {
    if (idType === 'DRIVERS_LICENSE' || idType === '3') {
      return 'DRIVERS_LICENSE';
    } else if (idType === 'PASSPORT' || idType === '2') {
      return 'PASSPORT';
    } else if (idType === 'MEDICARE' || idType === '1') {
      return 'MEDICARE';
    } else if (idType === 'NONE' || idType === '7') {
      return 'NONE';
    }
    return 'NONE';
  }

  formatDateCardColour(colorValue: string | number, formControl: any) {
    if (colorValue === '1' || colorValue === 1) {
      return _moment(formControl).format('MM/YYYY');
    } else {
      return _moment(formControl).format('DD/MM/YY');
    }
  }

  getResumePlanData(): SmsJourneyPlan {
    return JSON.parse(sessionStorage.getItem('resumePlan') || '');
  }

  returnInternalIdPerMedicareCardColour(cardColour: any) {
    const cardColours = { 1: 'G', 2: 'B', 3: 'Y' };
    // @ts-ignore
    const cardColorFinal = cardColours[cardColour];

    if (!cardColorFinal) {
      return null;
    }

    return cardColorFinal;
  }

  getObjectKeyByValue(array: any, pointer: string, desiredKey: string, value: string) {
    return array.filter((obj: any) => {
      if (obj[pointer] === value) {
        return obj[desiredKey];
      }
    });
  }

  convertStringToBoolean(value: string) {
    // only checks if the value has 'true' string inside and will return true, otherwise false
    return /true/i.test(value);
  }

  dropDownOpenChange(event: boolean, id: string) {
    if (!event) {
      this.activeDropDownFalseId = id;
    } else {
      this.activeDropDownTrueId = id;
    }
    return this.activeDropDownTrueId !== this.activeDropDownFalseId;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  previewPDFinNewWindow(pdf: any, id: string, download = false) {
    const wnd = open('');
    // @ts-ignore
    const doc = wnd.document.open();
    if (download) {
      doc.write(
        "<a href='data:application/pdf;base64," +
          pdf +
          "' download='" +
          id +
          ".pdf'>Download</a><br>"
      );
    }
    doc.write(`<iframe  width='100%' height='100%' src='data:application/pdf;base64, ${pdf}'/>`);
    doc.close();
  }

  printPDF(pdfInBase64: string) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    };

    const contentType = 'application/pdf';
    const blob = b64toBlob(pdfInBase64, contentType);

    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    // @ts-ignore
    iframe.contentWindow.print();
  }

  showFormFieldsValidationsErorrs(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.get(key)?.valid === false) {
        console.log(key + ' is invalid.');
      }
    });
  }

  public mergeJsonArrByKey(json1: any, json2: any, key: any) {
    const res = json1.map((x: any) =>
      Object.assign(
        x,
        json2.find((y: any) => y[key] === x[key])
      )
    );

    return res;
  }

  // If no date has been passed, default to new Date (today's date)
  addDaysToDate(daysToAdd: number, date: Date = new Date()): Date {
    return addDays(date, daysToAdd);
  }

  getUniqueObjectValuesFromArray(data: Array<any>, propertyName: string): Array<any> {
    return [...new Set(data.map(item => item[propertyName]))];
  }

  convertToMoney(value?: number): string {
    return '$' + value?.toFixed(2);
  }

  roundUpToTwoDecimal(value: number): number {
    return +(Math.round(value * 100) / 100).toFixed(2);
  }

  getTreatmentTypeName(treatmentType: string): string {
    const treatmentTypes: Array<any> = this.formFieldsService.getTreatmentTypes();
    return treatmentTypes ? treatmentTypes.find(x => x.value === treatmentType)?.name : '';
  }

  getTemplateTypeNameByInternalid(templateType: string): string {
    const templateTypes: Array<any> = this.formFieldsService.getTemplateTypes();
    return templateTypes ? templateTypes.find(x => x.internalid === templateType)?.name : '';
  }

  formatDateToString(value: Date | string, dateFormat: string = environment.dateFormat): string {
    if (!value) {
      return value;
    }

    if (value instanceof Date) {
      return format(value, dateFormat);
    }
    return value;
  }

  removeAllValidators(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      control?.clearValidators();
      control?.updateValueAndValidity();
    });
  }

  isDateLessThanGivenDate(checkDate: Date, givenDate: Date): boolean {
    // Remove time component for accurate comparison

    checkDate.setHours(0, 0, 0, 0);

    givenDate.setHours(0, 0, 0, 0);

    return checkDate < givenDate;
  }

  removeWhitespaces(value: string): string {
    return value.replace(/\s+/g, '');
  }

  private sort(
    data: any[],
    column: any,
    direction: string,
    type: string,
    options?: { dateTimeFormat: string }
  ): any[] {
    const compare = (v1: string, v2: string) => {
      if (type === 'number') {
        return (parseFloat(v1) || 0) < parseFloat(v2)
          ? -1
          : parseFloat(v1) > parseFloat(v2)
          ? 1
          : 0;
      }

      if (type === 'dateTime') {
        const dateTimeFormat = options?.dateTimeFormat
          ? options?.dateTimeFormat
          : 'dd/MM/yyyy h:m a';
        return parse(v1, dateTimeFormat, new Date()) < parse(v2, dateTimeFormat, new Date())
          ? -1
          : parse(v1, dateTimeFormat, new Date()) > parse(v2, dateTimeFormat, new Date())
          ? 1
          : 0;
      }

      if (type === 'date') {
        return parse(v1, 'dd/MM/yyyy', new Date()) < parse(v2, 'dd/MM/yyyy', new Date())
          ? -1
          : parse(v1, 'dd/MM/yyyy', new Date()) > parse(v2, 'dd/MM/yyyy', new Date())
          ? 1
          : 0;
      }

      try {
        return v1.toLowerCase() < v2.toLowerCase()
          ? -1
          : v1.toLowerCase() > v2.toLowerCase()
          ? 1
          : 0;
      } catch (error) {
        return (parseFloat(v1) || 0) < parseFloat(v2)
          ? -1
          : parseFloat(v1) > parseFloat(v2)
          ? 1
          : 0;
      }
    };

    if (direction === '' || column === '') {
      return data;
    } else {
      return [...data].sort((a, b) => {
        let res;
        const columnArr = column.split('.');
        if (columnArr.length === 2) {
          res = compare(a[columnArr[0]][columnArr[1]], b[columnArr[0]][columnArr[1]]);
          return direction === 'asc' ? res : -res;
        } else {
          res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        }
      });
    }
  }
}
