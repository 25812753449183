import { Injectable } from '@angular/core';
import { OrderedTextValuePair } from '@app/core/models/common/form-fields.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { CurrencyInputConfig } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PayInFullService {
  private CONFIG_KEY = 'pay_in_full';

  constructor(
    private configService: ConfigService,
    private userSettingsService: UserSettingsService
  ) {}

  getPifPaymentOptions(): OrderedTextValuePair[] {
    return this.userSettingsService.getPifPaymentOptions();
  }

  getTotalTreatmentCostConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'total_treatment_cost');
  }

  getDiscountConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'discount');
  }

  getOtherDiscountConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'other_discount');
  }

  getPaymentPlanTotalConfig(): Observable<CurrencyInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'payment_plan_total');
  }

  getPifPaymentOptionsDropdownConfig$(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'pif_payment_options_dd');
  }
}
