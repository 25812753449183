<div class="page-inner-menu">
  <ul>
    <li>
      <a
        routerLink="/payment-plan-summary"
        routerLinkActive="is-active"
        [routerLinkActiveOptions]="{ exact: true }"
        >All Plans</a
      >
    </li>
    <li *ngIf="this.user?.permissions['/payment-plan-pending-approval']">
      <a routerLink="/payment-plan-summary/approval" routerLinkActive="is-active">Approval Queue</a>
    </li>
    <li>
      <a routerLink="/payment-plan-summary/pending" routerLinkActive="is-active">Pending</a>
    </li>
    <li *ngIf="this.user?.permissions['/missed-payments']">
      <a routerLink="/payment-plan-summary/missed-payments" routerLinkActive="is-active"
        >Missed Payments</a
      >
    </li>
  </ul>
</div>
