import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormGroup,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { BnplCalculatorV2Service } from '@app/core/services/bnpl-calculator-v2/bnpl-calculator-v2.service';
import { BehaviorSubject } from 'rxjs';
import { FrequencyOptionsV2Service } from './frequency-options-v2.service';

@Component({
  selector: 'sliqpay-frequency-options-v2',
  templateUrl: './frequency-options-v2.component.html',
  styleUrls: ['./frequency-options-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [FrequencyOptionsV2Service, BnplCalculatorV2Service]
})
export class FrequencyOptionsV2Component implements OnInit, ControlValueAccessor {
  @Input() planType!: PlanType;
  @Input() showNumberOfPayments = false;

  form!: FormGroup;

  disabled = false;
  value = '';
  onChange!: (value: string) => void;
  onTouched!: () => void;

  get weekly(): AbstractControl | null {
    return this.form.get('weekly');
  }
  get totalTreatmentCost() {
    return this.rootFormGroup.control?.get('totalTreatmentCost');
  }
  get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }
  get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  options$ = new BehaviorSubject([{ internalid: '', name: '' }]);

  constructor(
    private service: FrequencyOptionsV2Service,
    private rootFormGroup: FormGroupDirective,
    @Self() @Optional() public control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const quoteForm = this.rootFormGroup.control?.get('quote') as FormArray;
    this.form = quoteForm.at(0) as FormGroup;

    this.options$.next(this.service.getFrequencies(this.planType));

    // For triggering template change
    this.form.valueChanges.subscribe(value => this.options$.next(this.options$.getValue()));
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue(newValue: string) {
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
  }

  getQuoteValue(freqName: string): number {
    if (!freqName) {
      return 0;
    }
    return +this.form.get(freqName.toLowerCase())?.value;
  }

  getNoOfPaymentByFreq(freq: string): number {
    return this.service.getNoOfPayments(freq, this.totalTreatmentCost?.value) || 0;
  }
}
