import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { UpdatePlanFieldCodes } from '@app/core/constants/payment-plan.const';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { AmendmentReasons } from '@app/core/enums/amendment-reason.enum';
import { PlanStatus } from '@app/core/enums/plan-status.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { SupportRequestType } from '@app/core/enums/support-request-type.enum';
import { AmendmentService } from '@app/core/services/amendment/amendment.service';
import { PlanEditPatientIdComponent } from '@app/payments/all-plans/plan-edit-patient-id/plan-edit-patient-id.component';
import { AmendmentRequestComponent } from '@app/payments/modals/amendment-request/amendment-request.component';
import { CommentModalComponent } from '@app/payments/modals/comment-modal/comment-modal.component';
import { AppThreeDotActionMenu } from '@app/shared/interfaces/three-dot-action-menu.interface';
import { ModalPdfViewerComponent } from '@core/components/modal-pdf-viewer/modal-pdf-viewer.component';
import { ModalComponent } from '@core/components/modal/modal.component';
import { SupportRequestFormComponent } from '@core/components/support-request-form/support-request-form.component';
import { PaymentPlanItemResponse } from '@core/models';
import { PaymentService } from '@core/services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { AllPlansActions, PaymentsActionMenuService } from './payments-action-menu.service';

@Component({
  selector: 'sliqpay-payments-action-menu',
  templateUrl: './payments-action-menu.component.html',
  styleUrls: ['./payments-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaymentsActionMenuService]
})
export class PaymentsActionMenuComponent implements OnInit, OnChanges, OnDestroy {
  PlanType = PlanType;
  PlanStatus = PlanStatus;
  RequestType = SupportRequestType;
  AllPlansActions = AllPlansActions;

  @Input() plan: PaymentPlanItemResponse | null = null;
  @Output() planChange: EventEmitter<PaymentPlanItemResponse> =
    new EventEmitter<PaymentPlanItemResponse>();
  @Output() dropdownChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  unsubscribe$ = new Subject<boolean>();

  actionsConfig$!: Observable<AppThreeDotActionMenu>;

  constructor(
    private modal: NgbModal,
    private paymentService: PaymentService,
    private paymentDataService: PaymentPlanDataService,
    private pamService: PaymentsActionMenuService,
    private amendmentService: AmendmentService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.actionsConfig$ = this.pamService.getActionsConfig(this.plan);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plan && !changes.plan.isFirstChange()) {
      this.actionsConfig$ = this.pamService.getActionsConfig(this.plan);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onActionChange(action: string): void {
    if (!this.plan) {
      return;
    }

    switch (action) {
      case AllPlansActions.Comment: {
        this.comment(this.plan);
        break;
      }
      case AllPlansActions.EditPatientId: {
        this.editPatientId(this.plan);
        break;
      }
      case AllPlansActions.RequestPlanChanges: {
        break;
      }
      case AllPlansActions.Amend: {
        this.openPlanAmendmentRequestModal(this.plan);
        break;
      }
      case AllPlansActions.Pause: {
        this.openPlanAmendmentRequestModal(this.plan, {
          reason: AmendmentReasons.FrequencyChange
        });
        break;
      }
      case AllPlansActions.Resume: {
        this.openSupportRequestModal(this.plan, this.RequestType.Resolve);
        break;
      }
      case AllPlansActions.Transfer: {
        this.openSupportRequestModal(this.plan, this.RequestType.Transfer);
        break;
      }
      case AllPlansActions.General: {
        this.openSupportRequestModal(this.plan, this.RequestType.General);
        break;
      }
      case AllPlansActions.PreviewSchedule: {
        this.previewSchedule(this.plan);
        break;
      }
      case AllPlansActions.PreviewAgreement: {
        this.previewAgreement(this.plan);
        break;
      }
      case AllPlansActions.DownloadSchedule: {
        this.downloadSchedule(this.plan);
        break;
      }
      case AllPlansActions.DownloadAgreement: {
        this.downloadAgreement(this.plan);
        break;
      }
      case AllPlansActions.SendAppLink: {
        this.sendAppLink(this.plan);
        break;
      }
      case AllPlansActions.ViewMissedPaymentDetails: {
        this.gotoMissedPaymentDetailsScreen(this.plan.ddrId, false);
        break;
      }
      case AllPlansActions.PaymentCollected: {
        this.markAsPaymentCollected(this.plan);
        break;
      }
    }
  }

  private previewSchedule(plan: PaymentPlanItemResponse): void {
    this.paymentService.getPDF(plan.key).subscribe(response => {
      if (response.data[0]) {
        this.previewPdf(response.data[0].files, plan.id + '.pdf');
      }
    });
  }

  private previewAgreement(plan: PaymentPlanItemResponse): void {
    this.paymentDataService.downloadAgreement(plan.key).subscribe(response => {
      if (response.data) {
        this.previewPdf(response.data, plan.id + '.pdf');
      }
    });
  }

  private previewPdf(files: any, fileName: string): void {
    const pdfInBase64 = files;
    const byteArray = new Uint8Array(
      atob(pdfInBase64)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const modal = this.modal.open(ModalPdfViewerComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.data = {
      title: 'Preview',
      pdfInBase64,
      pdfInUint8Array: byteArray,
      fileName
    };
  }

  private downloadSchedule(plan: PaymentPlanItemResponse): void {
    this.paymentService
      .getPDF(plan.key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response.data[0] && response.data[0].files) {
          this.downloadPdf(plan.id, response.data[0].files);
        }
      });
  }

  private downloadAgreement(plan: PaymentPlanItemResponse): void {
    this.paymentDataService.downloadAgreement(plan.key).subscribe(response => {
      if (response.data) {
        this.downloadPdf(plan.id, response.data);
      }
    });
  }

  private sendAppLink(plan: PaymentPlanItemResponse): void {
    this.paymentDataService.sendAppLink(plan).subscribe(response => {
      if (response.data[0].message) {
        this.toastService.success(response.data[0].message, 'Success');
        const addSmsCount =
          plan.rpRegisterSMSCount == '' ? 1 : parseInt(plan.rpRegisterSMSCount || '', 10) + 1;
        this.planChange.emit({ ...plan, rpRegisterSMSCount: addSmsCount.toString() });
      }
    });
  }

  private gotoMissedPaymentDetailsScreen(ddrID: string, isMissedPayment: boolean) {
    this.router.navigateByUrl(
      `payment-plan-summary/missed-payments/details/${isMissedPayment}/${ddrID}`
    );
  }

  private downloadPdf(planId: string, file: string): void {
    const pdfInBase64 = file;
    const linkSource = `data:application/pdf;base64,${pdfInBase64}`;
    const downloadLink = document.createElement('a');
    const fileName = planId + '.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  private comment(plan: PaymentPlanItemResponse): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan.ddrId;
    modal.componentInstance.propertyName = 'custrecord_mfa_ddr_provider_comments';
    modal.componentInstance.commentValue = plan.custrecord_mfa_ddr_provider_comments;
    modal.closed
      .pipe(
        filter(d => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.planChange.emit({
          ...plan,
          custrecord_mfa_ddr_provider_comments: data.custrecord_mfa_ddr_provider_comments
        });
      });
  }

  private editPatientId(plan: PaymentPlanItemResponse): void {
    const modal = this.modal.open(PlanEditPatientIdComponent, { centered: true });
    modal.componentInstance.data = plan;
    modal.componentInstance.fieldCode = UpdatePlanFieldCodes.PATIENT_ID;
    modal.closed
      .pipe(
        filter(d => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.planChange.emit({ ...plan, practice_patient_id_no: data.practice_patient_id_no });
      });
  }

  private openSupportRequestModal(
    plan: PaymentPlanItemResponse,
    supportRequestType: SupportRequestType
  ): void {
    const requestModal = this.modal.open(SupportRequestFormComponent, { size: 'lg' });
    requestModal.componentInstance.data = {
      ddrid: plan.id.replace('DDR-', ''),
      rpid: plan.rpId,
      supportRequestType
    };

    requestModal.closed.subscribe(response => {
      if (response && response.data) {
        const modalSuccessPopup = this.modal.open(ModalComponent, { centered: true, size: 'sm' });
        modalSuccessPopup.componentInstance.data = {
          title: 'Thank you',
          content: response.data.message,
          iconImage: 'icon-green-check',
          buttons: [
            {
              text: 'Close',
              class: 'btn-primary w-230 ml-1',
              value: 'true'
            }
          ]
        };
      }
    });
  }

  private openPlanAmendmentRequestModal(plan: PaymentPlanItemResponse, modalData: any = {}): void {
    this.amendmentService
      .getAmendmentDetails(plan.ddrId, plan.rpId, { checkStatus: true })
      .subscribe(ddrData => {
        this.amendmentService.setCurrentPlan(ddrData);
        const modalAmendmentPopup = this.modal.open(AmendmentRequestComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-wide',
          centered: true
        });
        modalAmendmentPopup.componentInstance.data = {
          ...modalData
        };
      });
  }

  private markAsPaymentCollected(plan: PaymentPlanItemResponse): void {
    this.pamService.markPifAsPaidAtPractice(plan);
  }
}
