import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse, GenericListResponse } from '@app/core/models';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { ImageService } from '@app/core/services/image/image.service';
import { environment } from '@environments/environment';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetProposalTemplateResponse } from '../../../templates/models/get-proposal-template.response';
import { PreviewProposalRequestPayload } from '../../../templates/models/preview-proposal-payload.interface';
import { SaveProposalTemplateRequest } from '../../../templates/models/save-proposal-template.request';

@Injectable({
  providedIn: 'root'
})
export class ProposalTemplateDataService {
  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private imageService: ImageService
  ) {}

  getProposalTemplate(): Observable<GetProposalTemplateResponse> {
    return this.httpClient
      .get<GenericListResponse<GetProposalTemplateResponse, ErrorResponse>>(
        `${this.apiUrlService.getProposalTemplate}`
      )
      .pipe(
        map(response => response.data[0]),
        switchMap(data => {
          if (data) {
            return of(data);
          }

          return forkJoin([
            this.imageService.getImageAsBase64(),
            this.getDefaultProposalProviderLogo$()
          ]).pipe(
            map(([defaultBanner, defaultLogo]) => {
              return {
                id: '',
                customrecord_templateproposal_txtsection: [],
                custrecord_asset_footer: defaultBanner,
                custrecord_asset_header: defaultBanner,
                custrecord_asset_logo: defaultLogo,
                custrecord_introtxt_printout: '',
                custrecord_introtxt_sms: '',
                custrecord_practice_notes: ''
              };
            })
          );
        })
      );
  }

  getProposalTemplate$(): Observable<GenericListResponse<GetProposalTemplateResponse, any>> {
    return this.httpClient.get<GenericListResponse<GetProposalTemplateResponse, any>>(
      `${this.apiUrlService.getProposalTemplate}`
    );
  }

  saveProposalTemplate(body: SaveProposalTemplateRequest): Observable<any> {
    // TO DO: Need to ask BE to remap response to handle text sections
    return this.httpClient.post<any>(`${this.apiUrlService.postSaveProposalTemplate}`, body);
  }

  previewProposal(payload: PreviewProposalRequestPayload): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlService.previewProposal}`, payload).pipe(
      map(response => {
        return response.base64PdfString;
      })
    );
  }

  private getDefaultProposalProviderLogo$(): Observable<string> {
    return this.imageService.getImageAsBase64(
      `assets/images/${environment.client}/default-proposal-logo.png`
    );
  }
}

export interface SaveTemplateProposalResponse {
  templateProposalId: number;
  message: string;
}
