<div class="mobile-loading-screen">
  <div class="__inner">
    <div class="logo text-center">
      <a href="#">
        <img src="{{ logoPath }}" alt="logo" />
      </a>
    </div>
    <div class="hero-text-section">
      <h3 class="mb-5 text-white">
        Retrieving Your<br />
        Payment Plan
        <ng-container *ngIf="providerName">
          <span>With</span><br />
          <span [innerHTML]="providerName"></span>
        </ng-container>
      </h3>
      <sliqpay-dot-loader></sliqpay-dot-loader>
      <p class="pt-5">Completing this application will take approximately 2 minutes.</p>
    </div>
  </div>
</div>
