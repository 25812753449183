<form
  class="form-group"
  [class.mb-5]="hasDateInputError || hasDateDdError || hasDatePickerError"
  [formGroup]="form"
>
  <label class="w-100 d-flex">
    <span class="text-truncate" title="{{ 'label' | lang : LANG_CONFIG_KEY | async }}">
      {{ 'label' | lang : LANG_CONFIG_KEY | async }}
    </span>
    <span *ngIf="isRequired" class="required-field"></span>
  </label>

  <div>
    <mat-radio-group
      class="d-flex align-items-center"
      (change)="onRadioChange($event)"
      formControlName="type"
    >
      <mat-radio-button [disableRipple]="true" [value]="FpdItemsInternalId.Specific">
        <div class="date-picker">
          <ng-container *ngIf="isTemplate; else datePicker">
            <span class="mr-2" [innerHTML]="'dtp_template' | lang : LANG_CONFIG_KEY | async"></span>
          </ng-container>

          <ng-template #datePicker>
            <sliqpay-date-picker
              formControlName="specificDate"
              [config]="dtpConfig$ | async"
            ></sliqpay-date-picker>

            <ng-container
              *ngIf="hasDatePickerError && (this.specificDate?.touched || this.specificDate?.value)"
            >
              <div class="invalid-feedback d-block position-absolute mt-4">
                <div *ngIf="specificDate?.errors?.valueLessThanMinDate">
                  Responsible party first debit date should not be on or before
                  {{ today | date : 'd MMMM yyyy' }}, or over {{ maxStartDateInDays }} days from
                  tomorrow
                </div>
                <div *ngIf="specificDate?.errors?.valueGreaterThanMaxDate">
                  Responsible party first debit date should not be over
                  {{ maxStartDateInDays }} days from tomorrow
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </mat-radio-button>

      <div class="text-gray-1 ml-2 mb-2"><span>Or</span></div>

      <mat-radio-button [disableRipple]="true" [value]="FpdItemsInternalId.Relative">
        <div class="input-with-dd">
          <div class="d-flex align-items-center">
            <sliqpay-number-input
              formControlName="relativeInput"
              [config]="relativeInputConfig$ | async"
            >
            </sliqpay-number-input>
            <sliqpay-dropdown
              formControlName="relativeOption"
              [config]="relativeDdConfig$ | async"
              [items]="ddItems"
              (changed)="onDropdownChange($event)"
            ></sliqpay-dropdown>
          </div>

          <span
            class="desc"
            [innerHTML]="'input_with_dd_desc' | lang : LANG_CONFIG_KEY | async"
          ></span>
        </div>
        <ng-container
          *ngIf="
            hasDateInputError &&
            (this.relativeInput?.touched ||
              this.relativeInput?.value ||
              this.relativeInput?.value === 0)
          "
        >
          <div class="invalid-feedback d-block position-absolute mt-4">
            <div *ngIf="hasDateInputError.min">
              Minimum value of start date should not be lesser than 1
            </div>
            <div *ngIf="hasDateInputError.max">
              Maximum value of start date should not be greater than
              {{ selectedDdItem.max + ' ' + selectedDdItem.name }}
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            hasDateDdError &&
            (this.relativeOption?.touched || this.relativeOption?.value) &&
            !hasDateInputError
          "
        >
          <div class="invalid-feedback d-block position-absolute mt-4">
            <div *ngIf="hasDateDdError.required">Date option is required</div>
          </div>
        </ng-container>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</form>
