<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="item.subMenu; else singleItem">
        <button mat-menu-item [matMenuTriggerFor]="submenu.menu" [disableRipple]="true">
          <i
            *ngIf="item.iconBefore"
            class="{{ 'uil ' + item.iconBefore }}"
            [ngClass]="item.itemClass ?? ''"
          ></i>
          {{ item.label }}
        </button>
        <sliqpay-menu-panel
          #submenu
          [items]="item.subMenu.menuItems"
          (itemClicked)="onItemClick($event)"
        ></sliqpay-menu-panel>
      </ng-container>

      <ng-template #singleItem>
        <button mat-menu-item [disableRipple]="true" (click)="onItemClick(item.value)">
          <i
            *ngIf="item.iconBefore"
            class="{{ 'uil ' + item.iconBefore }}"
            [ngClass]="item.itemClass ?? ''"
          ></i>
          {{ item.label }}
        </button>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
