import { Injectable } from '@angular/core';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { DdrPlanType } from '@app/core/models/common/form-fields.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormFieldsService } from '../form-fields/form-fields.service';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PlanTypeService {
  private currentPlanType$ = new BehaviorSubject<PlanType>(this.getInitialPlanTypeByAccess());

  constructor(
    private formFieldsService: FormFieldsService,
    private userSettingsService: UserSettingsService
  ) {}

  isPlanTypeBnpl(planType: PlanType | string | null): boolean {
    return planType === PlanType.Bnpl;
  }

  isPlanTypePlus(planType: PlanType | string | null): boolean {
    return planType === PlanType.Guaranteed;
  }

  isPlanTypeConnect(planType: PlanType | string | null): boolean {
    return planType === PlanType.NonGuaranteed;
  }

  isPlanTypeReactiveConnect(planType: PlanType | string | null, isProactive: boolean): boolean {
    return planType === PlanType.NonGuaranteed && !isProactive;
  }

  getPlanTypes(): DdrPlanType[] {
    return this.formFieldsService.getPlanTypes();
  }

  getInitialPlanTypeByAccess(): PlanType {
    if (this.userSettingsService.isProactiveConnectOnlyProvider()) {
      return PlanType.NonGuaranteed;
    } else {
      if (this.userSettingsService.isPlusEnabled()) {
        return PlanType.Guaranteed;
      } else {
        return PlanType.Bnpl;
      }
    }
  }

  isProviderHasOnlyConnectEnabled(): boolean {
    const planTypes = this.getPlanTypesWithAccess();
    return planTypes.length === 1 && planTypes[0].internalid === PlanType.NonGuaranteed;
  }

  setCurrentPlanType(planType: PlanType | null): void {
    this.currentPlanType$.next(planType ? planType : this.getInitialPlanTypeByAccess());
  }

  getCurrentPlanType(): Observable<PlanType> {
    return this.currentPlanType$.asObservable();
  }

  getCurrentPlanTypeSnapshot(): PlanType {
    return this.currentPlanType$.value;
  }

  getPlanTypeLabel(planType: PlanType): string {
    return this.getPlanTypes().find(x => x.internalid === planType)?.custrecord_ddrtype_label || '';
  }

  getPlanTypesWithAccess(): DdrPlanType[] {
    return this.formFieldsService.getPlanTypes().filter(x => x.create_plan_dropdown);
  }

  getPlanTypeIdsWithAccess(): PlanType[] {
    return this.getPlanTypesWithAccess().map(planType => planType.internalid as PlanType);
  }

  getInitialPlanTypeOnViewEditPendingPlan(
    currentPlanType: PlanType,
    isProactiveConnect: boolean
  ): PlanType {
    const planTypeIdsWithUserAccess = this.getPlanTypeIdsWithAccess();
    let targetPlanType = currentPlanType;

    // if source plan is of type reactive connect, set copied plan to Plus
    if (!isProactiveConnect && targetPlanType === PlanType.NonGuaranteed) {
      targetPlanType = PlanType.Guaranteed;
    }

    return planTypeIdsWithUserAccess.includes(targetPlanType)
      ? targetPlanType
      : planTypeIdsWithUserAccess[0];
  }
}
