import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigSelector } from '@app/store/selectors/config.selector';
import { environment } from '@environments/environment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private isConfigLoadedSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private configSelector: ConfigSelector) {}

  // Get config by key-value pair
  getConfigValue(key: string, value: string): Observable<any> {
    return this.getJson().pipe(
      take(1),
      map(data => {
        const json: any = data;
        return json[key][value];
      })
    );
  }

  // Get config in store
  getJson(): Observable<any> {
    if (this.isConfigLoadedSubject.getValue()) {
      return this.configSelector.getConfig();
    }

    return this.setConfigInStore();
  }

  // Set the config to store
  // Config will merge base.json and <brand>.json
  setConfigInStore(): Observable<any> {
    return combineLatest([this.getBaseConfigJson(), this.getBrandConfigJson()]).pipe(
      take(1),
      map(([baseConfig, brandConfig]) => {
        const mergedJson = Object.assign({}, baseConfig, brandConfig);

        // Update config state in store
        this.configSelector.setConfig(mergedJson);

        // For checking if config has already been loaded
        this.isConfigLoadedSubject.next(true);

        return mergedJson;
      })
    );
  }

  isConfigLoaded(): Observable<boolean> {
    return this.isConfigLoadedSubject;
  }

  private getBrandConfigJson(): Observable<any> {
    return this.http.get('assets/config/' + environment.client.toLowerCase() + '.json');
  }

  private getBaseConfigJson(): Observable<any> {
    return this.http.get('assets/config/base.json');
  }
}
