<div *ngIf="isFormGroup">
  <form [formGroup]="form">
    <mat-checkbox
      formControlName="checkbox"
      class="pp-checkbox"
      [ngClass]="{
        'required-field': isRequiredTrue && config?.showRequiredMarker && !disabled,
        'checkbox-md': config?.size === 'md'
      }"
      [checked]="isChecked"
      [disabled]="disabled"
      (change)="onCheckboxChange($event.checked)"
    >
      <span [class.disabled]="disabled">
        <!-- DYNAMIC CONTENT -->
        <ng-container *ngIf="config?.isDynamicContent">
          <ng-content></ng-content>
        </ng-container>

        <!-- LANG CONFIG -->
        <ng-container *ngIf="config && config.langConfig; else displayActualLabel">
          <span
            [innerHTML]="
              config.langConfig.contentKey
                | lang : config.langConfig.langConfigKey : config.langConfig.strReplacements
                | async
            "
          >
          </span>
        </ng-container>

        <!-- PLAIN TEXT -->
        <ng-template #displayActualLabel>
          {{ config?.label }}
        </ng-template>
      </span>
    </mat-checkbox>
  </form>
</div>

<div *ngIf="!isFormGroup">
  <div class="non-form-group">
    <mat-checkbox
      [checked]="isChecked"
      [disabled]="disabled"
      [class.required-field]="isRequiredTrue && config?.showRequiredMarker && !disabled"
      (change)="onCheckboxChange($event.checked)"
      class="pp-checkbox"
    >
      <span [class.disabled]="disabled">
        <!-- DYNAMIC CONTENT -->
        <ng-container *ngIf="config?.isDynamicContent"> </ng-container>

        <!-- LANG CONFIG -->
        <ng-container *ngIf="config && config.langConfig; else displayActualLabel">
          <span
            [innerHTML]="
              config.langConfig.contentKey
                | lang : config.langConfig.langConfigKey : config.langConfig.strReplacements
                | async
            "
          >
          </span>
        </ng-container>

        <!-- PLAIN TEXT -->
        <ng-template #displayActualLabel>
          {{ config?.label }}
        </ng-template>
      </span>
    </mat-checkbox>
  </div>
</div>
