<div ngbDropdown class="dropdown" *ngIf="access">
  <button
    class="btn without-arrow pr-0"
    type="button"
    [id]="'dropdownMenuButton' + access"
    aria-expanded="false"
    ngbDropdownToggle
  >
    <i class="uil uil-ellipsis-v" style="font-size: 16px"></i>
  </button>
  <ul ngbDropdownMenu class="dropdown-menu" [attr.aria-labelledby]="'dropdownMenuButton' + access">
    <li>
      <a class="dropdown-item cursor-pointer d-flex" (click)="openEditAccess()">
        <i class="uil uil-edit-alt"></i> Edit
      </a>
    </li>
  </ul>
</div>
