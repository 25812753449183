<div class="modal-container">
  <div *ngIf="!data?.hideCloseBtn" class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>

  <ng-container *ngIf="data?.iconImage; else iconClass">
    <div class="d-flex justify-content-center">
      <div class="{{ data?.iconImage }}"></div>
    </div>
  </ng-container>
  <ng-template #iconClass>
    <!-- Will be deprecated -->
    <i *ngIf="data?.iconClass" [class]="'content-icon uil ' + data?.iconClass"></i>

    <!-- New implementation -->
    <div *ngIf="data?.uilIconClass" class="mb-4 d-flex justify-content-center">
      <i [class]="'uil-content-icon uil ' + data?.uilIconClass"></i>
    </div>
  </ng-template>

  <h4 *ngIf="data?.title">
    <ng-container *ngIf="data && data.title && data.langConfigKey; else displayActualTitle">
      <span
        [innerHTML]="data.title | lang : data.langConfigKey : data.strReplacements | async"
      ></span>
    </ng-container>
    <ng-template #displayActualTitle>
      <span [innerHTML]="data?.title"></span>
    </ng-template>
  </h4>
  <p *ngIf="data?.content">
    <ng-container *ngIf="data && data.content && data.langConfigKey; else displayActualContent">
      <span
        [innerHTML]="data.content | lang : data.langConfigKey : data.strReplacements | async"
      ></span>
    </ng-container>
    <ng-template #displayActualContent>
      <span [innerHTML]="data?.content"></span>
    </ng-template>
  </p>
  <div class="action" *ngIf="data?.buttons?.length" [class]="data?.buttonBlockClass">
    <button
      *ngFor="let btn of data?.buttons"
      [class]="'btn ' + btn.class"
      (click)="activeModal.close(btn?.value)"
    >
      {{ btn?.text }}
    </button>
  </div>
</div>
