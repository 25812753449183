import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmendmentPayload } from '@app/core/models/support/amendment-payload.model';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmendmentDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  createAmendmentElement(body: AmendmentPayload): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.createAmendmentElement, body);
  }

  getAmendmentDetails(
    ddrId: string,
    rpId: string,
    options?: { checkStatus?: boolean }
  ): Observable<any> {
    return this.httpClient.get<any>(this.apiUrlService.amendmentDetails, {
      params: {
        ddrId,
        rpId,
        checkStatus: !!options?.checkStatus
      }
    });
  }

  createAmendmentAdjustmentRequest(body: AmendmentPayload): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.createAmendmentRequest, body);
  }

  updateAmendment(pendingPlanId: string, body: AmendmentPayload): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUrlService.updateAmendmentRequest}/${pendingPlanId}`,
      body
    );
  }

  acknowledgeAmendmentChanges(draftId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUrlService.acknowledgeAmendmentChanges}/${draftId}`,
      {
        isAmendmentChangesAcknowledged: true
      }
    );
  }
}
