import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'sliqpay-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @Input() config: any;
  @Output() queryChange = new EventEmitter();

  searchFormGroup!: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.config = {
      placeholder: 'Keyword',
      label: ''
    };
  }

  ngOnInit(): void {
    this.searchFormGroup = this.formBuilder.group({
      query: ['']
    });
  }

  onKeyup(event: any): void {
    this.queryChange.emit(event.target?.value);
  }
}
