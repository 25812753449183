<div class="modal-container">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h4 [innerHTML]="'header' | lang : LANG_CONFIG_KEY | async"></h4>
        <p [innerHTML]="'header_desc' | lang : LANG_CONFIG_KEY | async"></p>
      </div>
    </div>
    <form [formGroup]="supportTicketForm">
      <div class="row">
        <div class="col-12">
          <sliqpay-dropdown
            formControlName="templateOption"
            [config]="requestTypeDdConfig$ | async"
            [items]="supportTemplates"
            (changed)="setTemplateDetails($event)"
          >
          </sliqpay-dropdown>
        </div>
      </div>
      <div
        class="row"
        *ngIf="supportTicketForm.get('templateOption')?.value !== RequestType.ContactAccess"
      >
        <div class="col-12 col-md-6">
          <sliqpay-dropdown
            formControlName="rpID"
            [config]="rpNameDdConfig$ | async"
            [items]="ddrRPs$ | async"
            (changed)="findDDRofPR($event)"
          >
          </sliqpay-dropdown>
        </div>
        <div class="col-12 col-md-6">
          <sliqpay-dropdown
            formControlName="custevent_pa_related_ddr"
            [config]="relatedPlanDdConfig$ | async"
            [items]="relatedDDRs$ | async"
          >
          </sliqpay-dropdown>
        </div>
      </div>

      <!-- T8 -->
      <div
        *ngIf="supportTicketForm.get('templateOption')?.value === RequestType.Transfer"
        class="row"
      >
        <div class="col-12">
          <sliqpay-input
            formControlName="transferNewProvider"
            [config]="newProviderConfig$ | async"
          ></sliqpay-input>
        </div>
      </div>

      <!-- T9 -->
      <div
        *ngIf="supportTicketForm.get('templateOption')?.value === RequestType.ContactAccess"
        class="row"
      >
        <div class="col-12">
          <sliqpay-input
            formControlName="practiceName"
            [config]="practiceNameConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="practiceLocation"
            [config]="practiceLocationConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="employeeFullName"
            [config]="employeeFullNameConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="employeeRole"
            [config]="employeeRoleConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="employeeEmail"
            [config]="employeeEmailConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="employeeAssignRole"
            [config]="employeeAssignRoleConfig$ | async"
          ></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sliqpay-input
            formControlName="staffname"
            [config]="requestorConfig$ | async"
          ></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sliqpay-input formControlName="title" [config]="subjectConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sliqpay-input formControlName="email" [config]="emailConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Message</label>
            <textarea
              formControlName="incomingmessage"
              class="form-control"
              placeholder="Your message"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
    <div class="text-right">
      <sliqpay-button
        [config]="submitBtnConfig$ | async"
        [loading]="loading$ | async"
        (clicked)="submit()"
        [disabled]="!supportTicketForm.valid"
      ></sliqpay-button>
    </div>
  </div>
</div>
