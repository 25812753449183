<div class="sliqpay-dynamic-discount">
  <div class="d-flex align-items-center">
    <form [formGroup]="form">
      <fieldset class="d-flex align-items-end">
        <sliqpay-currency-input
          [class.d-none]="selectedType?.value !== DiscountType.Currency"
          formControlName="discountAmount"
          [config]="discountValueCurrencyInputConfig$ | async"
        ></sliqpay-currency-input>

        <sliqpay-number-input
          [class.d-none]="selectedType?.value !== DiscountType.Percentage"
          formControlName="discountAsPercentage"
          [config]="discountValueInputConfig$ | async"
          (changed)="calculateDiscount()"
        ></sliqpay-number-input>

        <sliqpay-dropdown
          class="discount-type-dd"
          formControlName="discountType"
          [config]="discountTypeDropdownConfig$ | async"
          [items]="discountTypeOptions"
          (changed)="typeChanged($event)"
        ></sliqpay-dropdown>
      </fieldset>
    </form>
  </div>
  <span class="sliqpay-discount-value" *ngIf="selectedType?.value === DiscountType.Percentage"
    >Discounted Amount:
    <span class="text-primary"> {{ discountAmount?.value || 0 | currency }}</span></span
  >
</div>
