import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiUrlService } from '@core/services/api-url.service';
import {
  AccessItemResponse,
  ErrorResponse,
  GenericListResponse,
  GenericModelResponse,
  SettingResponse
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class SettingDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  getSetting(): Observable<GenericModelResponse<SettingResponse, ErrorResponse>> {
    return this.httpClient.get<GenericModelResponse<SettingResponse, ErrorResponse>>(
      this.apiUrlService.settingsUrl
    );
  }

  getSettingAccess(): Observable<GenericListResponse<AccessItemResponse, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<AccessItemResponse, ErrorResponse>>(
      this.apiUrlService.settingsAccessUrl
    );
  }

  changePassword(body: {
    old_password: string;
    password: string;
    password_confirmation: string;
  }): Observable<GenericModelResponse<any, ErrorResponse>> {
    return this.httpClient.post<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.changePasswordUrl,
      body
    );
  }

  updateProviderAccess(body: any): Observable<GenericModelResponse<any, ErrorResponse>> {
    return this.httpClient.post<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.settingsUpdateAccessUrl,
      body
    );
  }

  postUpdateProviderSettings(payload: any): Observable<GenericModelResponse<any, ErrorResponse>> {
    return this.httpClient.post<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.updateProviderSettings,
      payload
    );
  }
}
