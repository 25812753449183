<div class="auth-page d-flex">
  <div class="left-sidebar">
    <div class="logo text-center">
      <a href="#">
        <img src="{{ logoPath }}" alt="logo" />
      </a>
    </div>
  </div>
  <section class="widget-login">
    <h3 class="pb-2" [innerHTML]="'h3' | lang : LANG_CONFIG_KEY | async"></h3>
    <p class="pr-5 mr-5" [innerHTML]="'instruction' | lang : LANG_CONFIG_KEY | async"></p>
    <form [formGroup]="form">
      <div class="widget-login__form">
        <sliqpay-input formControlName="email" [config]="emailConfig$ | async"></sliqpay-input>
      </div>
      <div class="row pt-2">
        <div class="col-6">
          <sliqpay-button
            [config]="backBtnConfig$ | async"
            (click)="goToLoginPage()"
          ></sliqpay-button>
        </div>
        <div class="col-6">
          <sliqpay-button
            [config]="sendBtnConfig$ | async"
            [loading]="loading$ | async"
            [disabled]="!form.valid"
            (clicked)="forgotPassword()"
          ></sliqpay-button>
        </div>
      </div>
    </form>
  </section>
</div>
