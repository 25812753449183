import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { DepositPayee } from '@app/core/enums/deposit.enum';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { ProviderType } from '@app/core/enums/provider-type.enum';
import { SmsJourneyPlan } from '@app/core/models';
import { TextValuePair } from '@app/core/models/common/form-fields.interface';
import { PostRpAffordabilityRequest } from '@app/core/models/payment/rp-affordability.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { HelperService } from '@app/core/services/helper.service';
import { PlanTypeService } from '@app/core/services/plan-type/plan-type.service';
import { ProposalTemplateService } from '@app/core/services/proposal-template/proposal-template.service';
import { RpAffordabilityService } from '@app/core/services/rp-affordability/rp-affordability.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { FpdItemsInternalId } from '@app/shared/components/first-payment-date-proposal/first-payment-date-proposal.component';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppCheckbox } from '@app/shared/interfaces/checkbox.interface';
import { AppTextarea } from '@app/shared/interfaces/textarea.interface';
import { GetProposalTemplateResponse } from '@app/templates/models/get-proposal-template.response';
import {
  PreviewProposalRequestPayload,
  ProposalTreatmentOption
} from '@app/templates/models/preview-proposal-payload.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderJourneyActionTypes } from '../enums/provider-journey-action-types.enum';
import {
  GetSendSmsResponse,
  PostCreateProposalPlanPayload,
  PostCreateProposalPlanTreatmentOption,
  PostCreateUnsubmittedProposalResponse,
  ProposalDataService
} from './data-services/proposal-data.service';

@Injectable()
export class CreateNewProposalService {
  private CONFIG_KEY = 'create_new_proposal';
  private currentProposalState: WritableSignal<SmsJourneyPlan | null> = signal(null);
  currentProposal = computed(() => this.currentProposalState());

  constructor(
    private configService: ConfigService,
    private proposalDataService: ProposalDataService,
    private helperService: HelperService,
    private planTypeService: PlanTypeService,
    private paymentPlanDataService: PaymentPlanDataService,
    private proposalTemplateService: ProposalTemplateService,
    private userSettingsService: UserSettingsService,
    private formFieldService: FormFieldsService,
    private rpAffordabilityService: RpAffordabilityService
  ) {}

  getAddOptionBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'add_option_btn');
  }

  getExitBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'exit_btn');
  }

  getEditBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'edit_btn');
  }

  postCreateUnsubmittedProposal(
    proposalData: any
  ): Observable<PostCreateUnsubmittedProposalResponse> {
    return this.proposalDataService.postCreateUnsubmittedProposal(
      this.mapPostCreateUnsubmittedProposalPayload(proposalData)
    );
  }

  getSendSms(ddr: string, ddrSource: string): Observable<GetSendSmsResponse> {
    return this.proposalDataService.sendSms(ddr, ddrSource);
  }

  // TODO: different handling for unsubmitted proposal, dont call fn from pending plan action menu component
  previewProposal(formValues: any, proposalCreationDate?: string): Observable<any> {
    const payload: PreviewProposalRequestPayload = this.mapPreviewProposalData(
      formValues,
      proposalCreationDate
    );

    return this.proposalDataService.previewProposal(payload);
  }

  getPendingProposalById$(savedPlanId: string): Observable<SmsJourneyPlan> {
    return this.paymentPlanDataService.getUnsubmittedPlanById$(savedPlanId, true).pipe(
      map(response => {
        const data = { ...response.data };
        data.plan_type = this.planTypeService.getInitialPlanTypeOnViewEditPendingPlan(
          data.plan_type,
          data.custrecord_ddrip_proactive
        );
        this.currentProposalState.set(data);
        return data;
      })
    );
  }

  getAllPracticeDocumentIds(): string[] {
    return this.proposalTemplateService.getPracticeDocumentIds();
  }

  isProposalTemplatesLoaded$(): Observable<boolean> {
    return this.proposalTemplateService.isLoaded$();
  }

  getProposalTemplate(): GetProposalTemplateResponse {
    return this.proposalTemplateService.getProposalTemplate();
  }

  getSmsIntroductoryTextareaConfig$(): Observable<AppTextarea> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'introductory_text_sms_textarea');
  }

  getPrintoutIntroductoryTextareaConfig$(): Observable<AppTextarea> {
    return this.configService.getConfigValue(
      this.CONFIG_KEY,
      'introductory_text_printout_textarea'
    );
  }

  getNoteTxtAreaConfig(): Observable<AppTextarea> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'notes_textarea');
  }

  getConfirmDetailsCbxConfig(): Observable<AppCheckbox> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'confirm_details_cbx').pipe(
      map((data: AppCheckbox) => {
        const config = { ...data };

        if (config.langConfig) {
          config.langConfig = {
            ...config.langConfig,
            strReplacements: [this.helperService.getObjUser()?.name]
          };
        }

        return config;
      })
    );
  }

  postCheckRpAffordability$(planDataValue: any): Observable<boolean> {
    return this.rpAffordabilityService.checkRpAffordabilityV2(
      this.mapPostCheckRpAffordability(planDataValue)
    );
  }

  getProceedDdItems(
    currentPlanType: string | null,
    showEditItem = false
  ): Observable<TextValuePair[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'proceed_items').pipe(
      map((data: TextValuePair[]) => {
        return data.filter((item: TextValuePair) => {
          if (this.planTypeService.isPlanTypeConnect(currentPlanType)) {
            switch (item.value) {
              case ProviderJourneyActionTypes.PrintAndSms:
                return this.userSettingsService.isSmsJourneyConnectEnabled();
              case ProviderJourneyActionTypes.OnsiteEntry:
                return this.userSettingsService.isRpEntryConnectEnabled();
              default:
                return item.value === ProviderJourneyActionTypes.PrintAndDownload;
            }
          }

          return item.value !== ProviderJourneyActionTypes.OnsiteEntry;
        });
      })
    );
  }

  private mapPostCreateUnsubmittedProposalPayload(
    proposalData: any
  ): PostCreateProposalPlanPayload {
    return {
      practice_id: this.helperService.getObjUser()['provider_entity_id'],
      plan_type: this.planTypeService.getCurrentPlanTypeSnapshot(),
      custrecord_ddrip_proactive: this.planTypeService.isPlanTypeConnect(
        this.planTypeService.getCurrentPlanTypeSnapshot()
      ),
      ddr_source: proposalData.ddrSource,
      custrecord_module_type: ModuleType.Proposal,
      rp_title: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.title
        : proposalData.rpDetails.title,
      rp_firstname: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.firstName
        : proposalData.rpDetails.firstName,
      rp_lastname: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.lastName
        : proposalData.rpDetails.lastName,
      rp_mobile_num: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.mobilePhone
        : proposalData.rpDetails.mobilePhone,
      rp_mobile_num2: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.mobilePhone2
        : proposalData.rpDetails.mobilePhone2,
      rp_dob: proposalData.patientDetails.isPatientIsRp
        ? this.helperService.formatDateToString(proposalData.patientDetails.dob)
        : this.helperService.formatDateToString(proposalData.rpDetails.dob),
      rp_email: proposalData.patientDetails.isPatientIsRp
        ? proposalData.patientDetails.email
        : proposalData.rpDetails.email,
      custrecord_mfa_ddr_copy_rp_fields: proposalData.patientDetails.isPatientIsRp,
      practice_patient_id_no: proposalData.patientDetails.patientId,
      patient_title: proposalData.patientDetails.title,
      patient_firstname: proposalData.patientDetails.firstName,
      patient_lastname: proposalData.patientDetails.lastName,
      custrecord_mfa_ddr_patient_dob: this.helperService.formatDateToString(
        proposalData.patientDetails.dob
      ),
      confim_details_cbx: proposalData.common.confirmDetailsCbx,
      reset_plan_progress: false,
      treatment_options: this.mapPostCreateProposalPlanTreatmentOption(
        proposalData.treatmentOptions
      ),
      practice_notes: proposalData.common.notes,
      introductory_text_sms: proposalData.common.introductoryTextSms,
      introductory_text_printout: proposalData.common.introductoryTextPrintout,
      custrecord_ddrip_old_saved_plan_id: proposalData.custrecord_ddrip_old_saved_plan_id,
      prev_ddr: proposalData.prev_ddr
    };
  }

  private mapPostCreateProposalPlanTreatmentOption(
    treatmentOptionFormValues: any[]
  ): PostCreateProposalPlanTreatmentOption[] {
    const treatmentOptions: PostCreateProposalPlanTreatmentOption[] = [];

    treatmentOptionFormValues.forEach(treatmentOption => {
      const hasPayInFull =
        this.userSettingsService.isPayInFullEnabled() && treatmentOption.isPifSelected;
      let option: PostCreateProposalPlanTreatmentOption = {
        custrecord_tp_treatment_option: treatmentOption.generalDetails.treatmentName,
        name: treatmentOption.generalDetails.treatmentName,
        selected: treatmentOption.selected,
        doctor_id: treatmentOption.generalDetails.doctor,
        treatment_type: treatmentOption.generalDetails.treatmentType,
        ortho_items: treatmentOption.generalDetails.orthoItems,
        lbl_ortho_items: this.getProcedureNames(treatmentOption.generalDetails.orthoItems),
        total_plan_value: treatmentOption.paymentPlanDetails.totalTreatmentCost,
        discount_type: treatmentOption.paymentPlanDetails.discountType,
        discount_amount: treatmentOption.paymentPlanDetails.discountAmount,
        discount_percent: treatmentOption.paymentPlanDetails.discountPercentage,
        deposit: treatmentOption.paymentPlanDetails.deposit,
        payment_plan_total: treatmentOption.paymentPlanDetails.paymentPlanTotal,
        payment_freq: treatmentOption.paymentPlanDetails.paymentFrequency,
        no_of_payments: treatmentOption.paymentPlanDetails.noOfPayments,
        custrecord_mfa_ddr_single_payments: treatmentOption.paymentPlanDetails.singlePayments,
        custrecord_override_term_length: treatmentOption.paymentPlanDetails.overrideTermLength,
        start_date: this.helperService.formatDateToString(
          treatmentOption.paymentPlanDetails.firstDebitDate.specificDate || ''
        ),
        first_payment_date_option: treatmentOption.paymentPlanDetails.firstDebitDate.relativeOption,
        first_payment_date_input: treatmentOption.paymentPlanDetails.firstDebitDate.relativeInput,
        first_payment_date_type: treatmentOption.paymentPlanDetails.firstDebitDate.type,
        discount_reason: treatmentOption.paymentPlanDetails.discountReason,
        quote: [
          {
            term_weeks: '',
            term_months: treatmentOption.paymentPlanDetails.quote[0].paymentPlanDurationInMonths,
            weekly: treatmentOption.paymentPlanDetails.quote[0].weekly,
            fortnightly: treatmentOption.paymentPlanDetails.quote[0].fortnightly,
            monthly: treatmentOption.paymentPlanDetails.quote[0].monthly
          }
        ],
        custrecord_pa_overwrite_payment_amount: 1,
        custrecord_mfa_ddr_term_length: '',
        treatment_description: treatmentOption.treatmentDescription,
        text_section_list: treatmentOption.practiceDocuments,
        pay_deposit_to: this.getPayDepositToValue(
          treatmentOption.paymentPlanDetails.deposit,
          treatmentOption.paymentPlanDetails.depositPayee
        ),
        is_payment_plan_selected: treatmentOption.isPaymentPlanSelected,
        is_pif_selected: hasPayInFull,
        doctor:
          this.userSettingsService.getProviderType() === ProviderType.ORTHO
            ? this.formFieldService.getDoctorByInternalId(treatmentOption.generalDetails.doctor)
            : null
      };

      if (hasPayInFull) {
        option = {
          ...option,
          pif_total_plan_value: +treatmentOption.payInFull.fullPaymentTotalTreatmentCost,
          pif_discount_amount: +treatmentOption.payInFull.fullPaymentDiscount,
          pif_other_discount_amount: +treatmentOption.payInFull.fullPaymentOtherDiscount,
          pif_discount_percent: treatmentOption.payInFull.fullPaymentDiscountPercentage,
          pif_discount_type: treatmentOption.payInFull.fullPaymentDiscountType,
          pif_pay_to: treatmentOption.payInFull.fullPaymentPaymentPreference
        };
      }

      treatmentOptions.push(option);
    });

    return treatmentOptions;
  }

  private mapPreviewProposalData(
    formValues: any,
    proposalCreationDate?: string
  ): PreviewProposalRequestPayload {
    const proposalTemplate = this.proposalTemplateService.getProposalTemplate();

    return {
      rp_firstname: formValues.patientDetails.isPatientIsRp
        ? formValues.patientDetails.firstName
        : formValues.rpDetails.firstName,
      patient_firstname: formValues.patientDetails.firstName,
      patient_lastname: formValues.patientDetails.lastName,
      practice_patient_id_no: formValues.patientDetails.patientId,
      showWatermark: false,
      entryPoint: 'providerJourney',
      treatment_options: this.mapGetPreviewProposalTreatmentOption(formValues.treatmentOptions),
      created: proposalCreationDate,
      prev_ddr: formValues.unsubId,
      customrecord_template_proposal: {
        customrecord_templateproposal_txtsection:
          proposalTemplate?.customrecord_templateproposal_txtsection || [],
        custrecord_asset_header: proposalTemplate?.custrecord_asset_header || '',
        custrecord_asset_footer: proposalTemplate?.custrecord_asset_footer || '',
        custrecord_asset_logo: proposalTemplate?.custrecord_asset_logo,
        custrecord_practice_notes: formValues.common.notes,
        custrecord_introtxt_sms: formValues.common.introductoryTextSms,
        custrecord_introtxt_printout: formValues.common.introductoryTextPrintout
      }
    };
  }

  private mapGetPreviewProposalTreatmentOption(
    treatmentOptionFormValues: any[]
  ): ProposalTreatmentOption[] {
    const treatmentOptions: ProposalTreatmentOption[] = [];

    treatmentOptionFormValues.forEach(treatmentOptionFormValue => {
      const isStartDateSpecific =
        treatmentOptionFormValue.paymentPlanDetails.firstDebitDate.type ===
        FpdItemsInternalId.Specific;

      const hasPayInFull =
        this.userSettingsService.isPayInFullEnabled() && treatmentOptionFormValue.isPifSelected;
      let option: ProposalTreatmentOption = {
        custrecord_tp_treatment_option: treatmentOptionFormValue.generalDetails.treatmentName,
        total_plan_value: treatmentOptionFormValue.paymentPlanDetails.totalTreatmentCost,
        discount_reason: treatmentOptionFormValue.paymentPlanDetails.discountReason,
        discount_amount: treatmentOptionFormValue.paymentPlanDetails.discountAmount,
        deposit: treatmentOptionFormValue.paymentPlanDetails.deposit,
        depositDescription: isStartDateSpecific
          ? 'Will be collected by DentiCare after Plan submission'
          : 'to be paid before treatment start date',
        first_payment_date_type: treatmentOptionFormValue.paymentPlanDetails.firstDebitDate.type,
        first_payment_date_input:
          treatmentOptionFormValue.paymentPlanDetails.firstDebitDate.relativeInput,
        first_payment_date_option:
          treatmentOptionFormValue.paymentPlanDetails.firstDebitDate.relativeOption,
        payment_plan_total: treatmentOptionFormValue.paymentPlanDetails.paymentPlanTotal,
        custrecord_mfa_ddr_single_payments:
          treatmentOptionFormValue.paymentPlanDetails.singlePayments,
        payment_freq: treatmentOptionFormValue.paymentPlanDetails.paymentFrequency,
        quote: [
          {
            term_months:
              treatmentOptionFormValue.paymentPlanDetails.quote[0].paymentPlanDurationInMonths,
            weekly: treatmentOptionFormValue.paymentPlanDetails.quote[0].weekly,
            fortnightly: treatmentOptionFormValue.paymentPlanDetails.quote[0].fortnightly,
            monthly: treatmentOptionFormValue.paymentPlanDetails.quote[0].monthly
          }
        ],
        start_date: isStartDateSpecific
          ? this.helperService.formatDateToString(
              treatmentOptionFormValue.paymentPlanDetails.firstDebitDate.specificDate || ''
            )
          : '',
        treatment_description: treatmentOptionFormValue.treatmentDescription,
        selected: true,
        created: this.helperService.formatDateToString(new Date()),
        custrecord_ppts_signature_base64: treatmentOptionFormValue.custrecord_ppts_signature_base64,
        text_section_list: treatmentOptionFormValue.practiceDocuments,
        pay_deposit_to: this.getPayDepositToValue(
          treatmentOptionFormValue.paymentPlanDetails.deposit,
          treatmentOptionFormValue.paymentPlanDetails.depositPayee
        ),
        lbl_ortho_items: this.getProcedureNames(treatmentOptionFormValue.generalDetails.orthoItems),
        is_pif_selected: hasPayInFull,
        is_payment_plan_selected: treatmentOptionFormValue.isPaymentPlanSelected,
        doctor:
          this.userSettingsService.getProviderType() === ProviderType.ORTHO
            ? this.formFieldService.getDoctorByInternalId(
                treatmentOptionFormValue.generalDetails.doctor
              )
            : null
      };

      if (hasPayInFull) {
        option = {
          ...option,
          pif_total_plan_value: +treatmentOptionFormValue.payInFull.fullPaymentTotalTreatmentCost,
          pif_discount_amount: +treatmentOptionFormValue.payInFull.fullPaymentDiscount,
          pif_other_discount_amount: +treatmentOptionFormValue.payInFull.fullPaymentOtherDiscount,
          pif_pay_to: treatmentOptionFormValue.payInFull.fullPaymentPaymentPreference
        };
      }

      treatmentOptions.push(option);
    });

    return treatmentOptions;
  }

  private getPayDepositToValue(depositAmount: number, depositPayee: DepositPayee) {
    if (depositAmount > 0) {
      return depositPayee;
    }

    return DepositPayee.PRACTICE;
  }

  private getProcedureNames(procedureItemIds: string[]): string[] {
    const orthoProcedureList = this.formFieldService.getOrthoItems();

    if (!procedureItemIds || !procedureItemIds.length) {
      return [];
    }

    return procedureItemIds.reduce<string[]>((list: string[], procedureId: string) => {
      const procedure = orthoProcedureList.find(p => p.internalid === procedureId);
      return procedure ? [...list, procedure.name] : list;
    }, []);
  }

  private mapPostCheckRpAffordability(planDataValue: any): PostRpAffordabilityRequest {
    const isPatientIsRp = planDataValue.patientDetails.isPatientIsRp;
    // Search for the lowest treatment option
    const treatmentOptions = planDataValue.treatmentOptions as any[];
    const minTreatmentOption = treatmentOptions.reduce((prev, curr) =>
      prev.paymentPlanDetails.paymentPlanTotal < curr.paymentPlanDetails.paymentPlanTotal
        ? prev
        : curr
    );
    const paymentPlanDetails = minTreatmentOption.paymentPlanDetails;

    return {
      plan_type: this.planTypeService.getCurrentPlanTypeSnapshot(),
      payment_plan_total: paymentPlanDetails.paymentPlanTotal,
      quote: [
        {
          term_months: paymentPlanDetails.quote[0].paymentPlanDurationInMonths,
          weekly: paymentPlanDetails.quote[0].weekly,
          fortnightly: paymentPlanDetails.quote[0].fortnightly,
          monthly: paymentPlanDetails.quote[0].monthly
        }
      ],
      rp_firstname: isPatientIsRp
        ? planDataValue.patientDetails.firstName
        : planDataValue.rpDetails.firstName,
      rp_lastname: isPatientIsRp
        ? planDataValue.patientDetails.lastName
        : planDataValue.rpDetails.lastName,
      rp_dob: isPatientIsRp
        ? this.helperService.formatDateToString(planDataValue.patientDetails.dob)
        : this.helperService.formatDateToString(planDataValue.rpDetails.dob),
      rp_mobile_num: isPatientIsRp
        ? planDataValue.patientDetails.mobilePhone
        : planDataValue.rpDetails.mobilePhone
    };
  }
}
