import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, throwError, zip } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SupportDataService } from '@app/core/data-services/support/support-data.service';
import { SupportRequestType } from '@app/core/enums/support-request-type.enum';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { ModalComponent } from '@core/components/modal/modal.component';
import { RpofDdr } from '@core/models';
import * as DDRValidator from '@core/services/ddr.validator';
import { HelperService } from '@core/services/helper.service';
import { SupportRequestFormService } from './support-request-form.service';

@Component({
  selector: 'sliqpay-support-request-form',
  templateUrl: './support-request-form.component.html',
  styleUrls: ['./support-request-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SupportRequestFormService]
})
export class SupportRequestFormComponent implements OnInit {
  @Input() data: any | null = null;

  LANG_CONFIG_KEY = 'support_request_form';
  RequestType = SupportRequestType;
  supportTicketForm: UntypedFormGroup;

  ddrRPs$ = new BehaviorSubject<RpofDdr[] | null>(null);
  previousTemplateOption = 0;
  fromActivePlan = false;
  minReducedAmount = 0.01;
  totalPaymentPlan = 0.01;
  maxResumeDateInDays = 90;
  reducedAmountConfig = {
    defaultValue: false
  };

  incomingMessage = '';

  ddrRpsData$ = new BehaviorSubject<RpofDdr[] | null>(null);
  ddrRpsNonBnplData$ = new BehaviorSubject<RpofDdr[] | null>(null);
  relatedDDRs$ = new BehaviorSubject<any[] | null>(null);
  unsubscribe$ = new Subject<boolean>();
  loading$ = new BehaviorSubject(false);

  // Form fields
  supportTemplates = [];
  suspensionReasons = [];

  // Config
  newProviderConfig$!: Observable<AppInput>;
  practiceNameConfig$!: Observable<AppInput>;
  practiceLocationConfig$!: Observable<AppInput>;
  employeeFullNameConfig$!: Observable<AppInput>;
  employeeRoleConfig$!: Observable<AppInput>;
  employeeEmailConfig$!: Observable<AppInput>;
  employeeAssignRoleConfig$!: Observable<AppInput>;
  requestorConfig$!: Observable<AppInput>;
  subjectConfig$!: Observable<AppInput>;
  emailConfig$!: Observable<AppInput>;
  submitBtnConfig$!: Observable<AppButton>;
  requestTypeDdConfig$!: Observable<AppDropdown>;
  rpNameDdConfig$!: Observable<AppDropdown>;
  relatedPlanDdConfig$!: Observable<AppDropdown>;
  debitResumeDatePicker$!: Observable<AppDatePicker>;

  constructor(
    private fb: UntypedFormBuilder,
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
    public helperService: HelperService,
    private supportService: SupportDataService,
    private srfService: SupportRequestFormService
  ) {
    this.supportTicketForm = this.fb.group({
      staffname: ['', Validators.required],
      email: [this.helperService.getObjUser()?.email, DDRValidator.rp_email_validator],
      custevent_pa_related_ddr: [],
      title: ['', Validators.required],
      reducedAmount: [],
      reducedAmountOptions: [],
      requestSendDDR: [],
      // Account Enquiry
      custevent6: ['2'],
      priority: ['2', Validators.required],
      status: ['1', Validators.required],
      attachmentName: [],
      attachment: [],
      incomingmessage: [],
      // General Enquiry
      custevent_support_prov_type: [],
      templateOption: [null, Validators.required],
      rpID: [],
      rpName: [],
      // recurringStartDate: [],
      recurringAmount: [],
      // T4
      suspenionDate: [],
      suspensionReason: [],
      // T5
      productsUsedtoDate: [],
      cancellationDate: [],
      cancellationSettleAmount: [],
      cancellationReason: [],
      // T8
      transferNewProvider: [],
      // T9
      practiceName: [],
      practiceLocation: [],
      employeeFullName: [],
      employeeRole: [],
      employeeEmail: [''],
      employeeAssignRole: [],
      // Reduce amount shared by T1 T2 T10
      custrecord_provider_amendment_amount: []
    });
  }

  get debitResumeDate(): AbstractControl | null {
    return this.supportTicketForm.get('suspenionDate');
  }

  get maxDateValidator() {
    const maxDate = new Date();
    return {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate()
    };
  }

  get maxDebitResumeDate(): NgbDateStruct {
    const maxDate = this.helperService.addDaysToDate(this.maxResumeDateInDays);
    return {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate()
    };
  }

  ngOnInit(): void {
    this.setObjFormFields();
    this.setConfig();

    if (this.data) {
      if (this.data['supportRequestType']) {
        this.supportTicketForm.get('templateOption')?.setValue(this.data['supportRequestType']);
        this.setTemplateDetails(this.data['supportRequestType']);
      }

      if (this.data['rpid']) {
        const paramRPId = this.data['rpid'];
        this.supportTicketForm.get('rpID')?.setValue(paramRPId);
        this.findDDRofPR(paramRPId);
      }
    }

    zip(
      this.supportService.fetchRPofDDRList('true').pipe(takeUntil(this.unsubscribe$)),
      this.supportService.fetchRPofDDRList().pipe(takeUntil(this.unsubscribe$))
    ).subscribe(response => {
      if (response) {
        if (this.helperService.checkAPIResponse(response[0])) {
          const data = this.helperService.getAPIDataResponse(response[0]);
          this.ddrRpsNonBnplData$.next(data);
        }
        if (this.helperService.checkAPIResponse(response[1])) {
          const data = this.helperService.getAPIDataResponse(response[1]);
          this.ddrRpsData$.next(data);
        }
      }

      this.setDdrRpList();
    });
  }

  findDDRofPR(val: any): void {
    if (val) {
      this.supportService.fetchDDRs(val, this.isBnpl()).subscribe(response => {
        if (this.helperService.checkAPIResponse(response)) {
          this.relatedDDRs$.next(response.data);
          if (response.data[0]) {
            this.supportTicketForm.get('custevent_pa_related_ddr')?.setValue(response.data[0].id);
          }

          // Continue from "Request Plan Change"
          if (this.data?.ddrid) {
            this.supportTicketForm
              .get('custevent_pa_related_ddr')
              ?.setValue(parseInt(this.data['ddrid'], 10));
          }
        }
      });
    } else {
      this.supportTicketForm.get('custevent_pa_related_ddr')?.setValue(null);
      this.relatedDDRs$.next(null);
    }
    this.clearReducedAmount();
  }

  clearReducedAmount() {
    this.supportTicketForm.get('reducedAmount')?.setValue(0);
  }

  submit() {
    if (!this.supportTicketForm.valid) {
      this.supportTicketForm.markAllAsTouched();
      return;
    }

    const templateOption = this.supportTicketForm.get('templateOption')?.value;
    const titleToAppend =
      templateOption === SupportRequestType.General ||
      templateOption === SupportRequestType.ContactAccess ||
      !templateOption
        ? 'APP : '
        : 'APP : ';
    const ddr = this.helperService.getObjectByValue(
      this.relatedDDRs$.value || [],
      'id',
      this.supportTicketForm.get('custevent_pa_related_ddr')?.value
    );
    const ddrName = ddr ? ddr.name : '';
    let titleAppended = ddrName + ' ' + this.supportTicketForm.get('title')?.value;
    titleAppended = titleToAppend + ' ' + titleAppended;

    this.incomingMessage = this.setIncomingMessage();
    if (!this.supportTicketForm.valid) {
      return;
    }
    this.loading$.next(true);
    const confirmSubmit = this.modal.open(ModalComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    confirmSubmit.componentInstance.data = {
      title: 'Confirm',
      content: `Are you sure you wish to submit this support request?`,
      iconImage: 'icon-green-check',
      buttons: [
        {
          text: 'No',
          class: 'btn-red w-170 mr-1',
          value: 'false'
        },
        {
          text: 'Yes',
          class: 'btn-primary w-170 ml-1',
          value: 'true'
        }
      ]
    };
    confirmSubmit.closed.subscribe(closed => {
      this.loading$.next(false);
      if (closed === 'false') {
        confirmSubmit.close();
      }
      if (closed === 'true') {
        this.loading$.next(true);

        this.supportService
          .submit({
            ...this.supportTicketForm.value,
            title: titleAppended,
            incomingmessage: this.incomingMessage
          })
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.loading$.next(false);
              return throwError(error);
            })
          )
          .subscribe(response => {
            this.activeModal.close(response);
          });
      }
    });
  }

  setIncomingMessage() {
    const templateOption = this.supportTicketForm.get('templateOption')?.value;

    const oriIncomingMessage = this.supportTicketForm.get('incomingmessage')?.value;
    let newIncomingMessage =
      'Logged By: ' +
      this.supportTicketForm.get('staffname')?.value +
      '\n Message: ' +
      oriIncomingMessage;

    switch (templateOption) {
      case SupportRequestType.Suspend: {
        const localSuspensionReason =
          this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
            this.suspensionReasons,
            'internalid',
            this.supportTicketForm.get('suspensionReason')?.value,
            'name'
          );
        newIncomingMessage =
          newIncomingMessage +
          '\n Date Debit Resumes: ' +
          this.supportTicketForm.get('suspenionDate')?.value +
          '\n Reason for Suspension: ' +
          localSuspensionReason;
        break;
      }
      case SupportRequestType.Cancel: {
        newIncomingMessage =
          newIncomingMessage +
          '\n Requested Cancellation Date: ' +
          this.supportTicketForm.get('cancellationDate')?.value +
          '\n Amount to Settle Payment Plan at: ' +
          this.supportTicketForm.get('cancellationSettleAmount')?.value +
          '\n What is the treatment cost to date provided to your patient (not including the deposit paid to practice): ' +
          this.supportTicketForm.get('cancellationReason')?.value;
        break;
      }
      case SupportRequestType.Resolve: {
        break;
      }
      case SupportRequestType.General: {
        break;
      }
      case SupportRequestType.Transfer: {
        newIncomingMessage =
          newIncomingMessage +
          '\n New Provider/Practice: ' +
          this.supportTicketForm.get('transferNewProvider')?.value;
        break;
      }
      case SupportRequestType.ContactAccess: {
        newIncomingMessage =
          newIncomingMessage +
          '\n Practice Name: ' +
          this.supportTicketForm.get('practiceName')?.value +
          '\n Practice Location: ' +
          this.supportTicketForm.get('practiceLocation')?.value +
          "\n Employee's Full Name: " +
          this.supportTicketForm.get('employeeFullName')?.value +
          "\n Employee's Role: " +
          this.supportTicketForm.get('employeeRole')?.value +
          "\n Employee's Email Address: " +
          this.supportTicketForm.get('employeeEmail')?.value +
          '\n Standard or Admin User: ' +
          this.supportTicketForm.get('employeeAssignRole')?.value;
        break;
      }
    }
    return newIncomingMessage;
  }

  checkReducedAmount() {
    const templateOption = this.supportTicketForm.get('templateOption')?.value;
    if (
      templateOption === SupportRequestType.Reduce ||
      templateOption === SupportRequestType.ReduceHf
    ) {
      const ddrId = this.supportTicketForm.get('custevent_pa_related_ddr')?.value;
      this.totalPaymentPlan = this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
        this.relatedDDRs$.value || [],
        'id',
        ddrId,
        'Total Payment Plan'
      );
      this.supportTicketForm.controls['reducedAmount'].setValidators([
        Validators.required,
        Validators.min(this.minReducedAmount),
        Validators.max(this.totalPaymentPlan)
      ]);
      this.supportTicketForm.controls['reducedAmount'].updateValueAndValidity();
    }
  }

  resetDynamicValidators() {
    this.supportTicketForm.controls['rpID'].setValidators(null);
    this.supportTicketForm.controls['custevent_pa_related_ddr'].setValidators(null);
    this.supportTicketForm.controls['reducedAmount'].setValidators(null);
    this.supportTicketForm.controls['requestSendDDR'].setValidators(null);
    // this.supportTicketForm.controls['recurringStartDate'].setValidators(null);
    this.supportTicketForm.controls['recurringAmount'].setValidators(null);
    this.supportTicketForm.controls['attachmentName'].setValidators(null);
    this.supportTicketForm.controls['suspenionDate'].setValidators(null);
    this.supportTicketForm.controls['suspensionReason'].setValidators(null);
    this.supportTicketForm.controls['cancellationDate'].setValidators(null);
    this.supportTicketForm.controls['cancellationSettleAmount'].setValidators(null);
    this.supportTicketForm.controls['cancellationDate'].setValidators(null);
    this.supportTicketForm.controls['cancellationReason'].setValidators(null);
    this.supportTicketForm.controls['transferNewProvider'].setValidators(null);
    this.supportTicketForm.controls['practiceName'].setValidators(null);
    this.supportTicketForm.controls['practiceLocation'].setValidators(null);
    this.supportTicketForm.controls['employeeFullName'].setValidators(null);
    this.supportTicketForm.controls['employeeRole'].setValidators(null);
    this.supportTicketForm.controls['employeeEmail'].setValidators(null);
    this.supportTicketForm.controls['employeeAssignRole'].setValidators(null);
  }

  setDdrRpList() {
    const ddrRPs = this.isBnpl() ? this.ddrRpsData$.value : this.ddrRpsNonBnplData$.value;
    this.ddrRPs$.next(ddrRPs);
  }

  setTemplateDetails(templateValue: SupportRequestType) {
    if (!templateValue) {
      return;
    }
    this.resetDynamicValidators();

    this.supportTicketForm.controls['rpID'].setValidators(Validators.required);
    this.supportTicketForm.controls['custevent_pa_related_ddr'].setValidators(Validators.required);

    switch (templateValue) {
      case SupportRequestType.Suspend: {
        this.supportTicketForm.controls['suspenionDate'].setValidators(Validators.required);
        this.supportTicketForm.controls['suspensionReason'].setValidators(Validators.required);
        break;
      }
      case SupportRequestType.Resolve: {
        break;
      }
      case SupportRequestType.General: {
        this.supportTicketForm.controls['rpID'].setValidators(null);
        this.supportTicketForm.controls['custevent_pa_related_ddr'].setValidators(null);
        break;
      }
      case SupportRequestType.Transfer: {
        this.supportTicketForm.controls['transferNewProvider'].setValidators(Validators.required);
        break;
      }
      case SupportRequestType.ContactAccess: {
        this.supportTicketForm.controls['rpID'].setValidators(null);
        this.supportTicketForm.controls['custevent_pa_related_ddr'].setValidators(null);
        this.supportTicketForm.controls['practiceName'].setValidators(Validators.required);
        this.supportTicketForm.controls['practiceLocation'].setValidators(Validators.required);
        this.supportTicketForm.controls['employeeFullName'].setValidators(Validators.required);
        this.supportTicketForm.controls['employeeRole'].setValidators(Validators.required);
        this.supportTicketForm.controls['employeeEmail'].setValidators(
          DDRValidator.rp_email_validator
        );
        this.supportTicketForm.controls['employeeAssignRole'].setValidators(Validators.required);
        break;
      }
      default: {
        this.supportTicketForm.controls['rpID'].setValidators(null);
        this.supportTicketForm.controls['custevent_pa_related_ddr'].setValidators(null);
        break;
      }
    }

    this.setDdrRpList();
    this.updateTreeValidity(this.supportTicketForm);
    // If switch from support type (General/empty) then clear RP and DDR as that list will consists of BNPL list, which is the master list
    if (
      this.previousTemplateOption === SupportRequestType.General ||
      this.previousTemplateOption === SupportRequestType.ContactAccess
    ) {
      if (
        templateValue !== SupportRequestType.General &&
        templateValue !== SupportRequestType.ContactAccess &&
        templateValue > 0
      ) {
        if (!this.fromActivePlan) {
          const selectedRp = this.supportTicketForm.get('rpID')?.value;
          const matchRpId = this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
            this.ddrRpsNonBnplData$.value as any,
            'rpId',
            selectedRp,
            'rpIdName'
          );
          if (selectedRp && !matchRpId) {
            this.supportTicketForm.get('rpID')?.setValue(null);
            this.supportTicketForm.get('custevent_pa_related_ddr')?.setValue(null);
          }
        }
      }
      this.fromActivePlan = false;
    }

    // Get subject title from form fields data.
    const title = this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
      this.supportTemplates,
      'internalid',
      templateValue,
      'title'
    );

    this.supportTicketForm.get('title')?.setValue(title);
    // Get provider case type from form fields data.
    const custevent_support_prov_type =
      this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
        this.supportTemplates,
        'internalid',
        templateValue,
        'providerCaseType'
      );
    this.supportTicketForm
      .get('custevent_support_prov_type')
      ?.setValue(custevent_support_prov_type);
    this.previousTemplateOption = templateValue;
    this.supportTicketForm.controls['rpID'].updateValueAndValidity();
    this.supportTicketForm.controls['custevent_pa_related_ddr'].updateValueAndValidity();

    this.supportTicketForm.updateValueAndValidity();
    this.setSubmitBtnConfig();
  }

  isBnpl() {
    const templateOption = this.supportTicketForm.get('templateOption')?.value;
    return (
      templateOption === SupportRequestType.General ||
      templateOption === SupportRequestType.ContactAccess
    );
  }

  updateTreeValidity(group: UntypedFormGroup | UntypedFormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      // @ts-ignore
      const abstractControl = group.controls[key];

      if (
        abstractControl instanceof UntypedFormGroup ||
        abstractControl instanceof UntypedFormArray
      ) {
        this.updateTreeValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  private setConfig(): void {
    this.newProviderConfig$ = this.srfService.getNewProvideConfig();
    this.practiceNameConfig$ = this.srfService.getPracticeNameConfig();
    this.practiceLocationConfig$ = this.srfService.getPracticeLocationConfig();
    this.employeeFullNameConfig$ = this.srfService.getEmployeeFullNameConfig();
    this.employeeRoleConfig$ = this.srfService.getEmployeeRoleConfig();
    this.employeeEmailConfig$ = this.srfService.getEmployeeEmailConfig();
    this.employeeAssignRoleConfig$ = this.srfService.getEmployeeAssignRoleConfig();
    this.requestorConfig$ = this.srfService.getRequestorConfig();
    this.subjectConfig$ = this.srfService.getSubjectConfig();
    this.emailConfig$ = this.srfService.getEmailConfig();
    this.setSubmitBtnConfig();
    this.requestTypeDdConfig$ = this.srfService.getRequestTypeDdConfig();
    this.rpNameDdConfig$ = this.srfService.getRpNameDdConfig();
    this.relatedPlanDdConfig$ = this.srfService.getRelatedPlanDdConfig();
    this.debitResumeDatePicker$ = this.srfService.getDebitResumeDateDatepickerConfig$().pipe(
      map(config => {
        return {
          ...config,
          maxDate: new Date(
            this.maxDebitResumeDate.year,
            this.maxDebitResumeDate.month - 1,
            this.maxDebitResumeDate.day
          )
        };
      })
    );
  }

  private setSubmitBtnConfig(): void {
    this.submitBtnConfig$ = this.srfService.getSubmitBtnConfig();
  }

  private setObjFormFields(): void {
    this.supportTemplates = this.srfService.getObjFormFieldByValue('support_call_options');
    this.suspensionReasons = this.srfService.getObjFormFieldByValue(
      'customlist_suspension_reason_list_special'
    );
  }
}
