import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { ProposalTemplateService } from '@app/core/services/proposal-template/proposal-template.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HomePageService {
  private CONFIG_KEY = 'home_page';

  constructor(
    private configService: ConfigService,
    private proposalTemplateService: ProposalTemplateService
  ) {}

  getViewPlansBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'view_plans_btn');
  }

  getPlanAssistantBtnConfig$(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'payment_plan_assistant_btn');
  }

  getProviderLogo$(): Observable<string> {
    return this.proposalTemplateService
      .getProposalTemplate$()
      .pipe(map(template => template.custrecord_asset_logo));
  }
}
