import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { AccessItemResponse } from '@core/models/setting';
import { SettingsChangeAccessComponent } from '../setting-change-access/settings-change-access.component';

@Component({
  selector: 'sliqpay-setting-action-menu',
  templateUrl: './setting-action-menu.component.html',
  styleUrls: ['./setting-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingActionMenuComponent implements OnDestroy {
  @Input() access: AccessItemResponse | null = null;
  @Input() accesses: AccessItemResponse[] | null = null;
  @Output() edited = new EventEmitter();

  unsubscribe$ = new Subject<boolean>();

  constructor(private modal: NgbModal) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openEditAccess(): void {
    const modal = this.modal.open(SettingsChangeAccessComponent, { centered: true });
    modal.componentInstance.access = this.access;
    modal.componentInstance.accesses = this.accesses;
    modal.closed.subscribe(res => this.edited.emit(res));
  }
}
