import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { SliderConfig } from '@app/shared/interfaces/slider.interface';

@Component({
  selector: 'sliqpay-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements ControlValueAccessor {
  @Input() config!: SliderConfig;
  @Input() value = 0;
  @Output() changed = new EventEmitter();

  onChange!: (value: number) => void;
  onTouch!: () => void;

  disabled?: boolean;

  get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  constructor(@Self() @Optional() private control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateFormValue(): void {
    this.onChange(this.value);
  }

  formatThumb(): any {
    return (value: number) => {
      if (this.config.unit) {
        return `${value} ${this.config?.unit}`.trim();
      }
      return value;
    };
  }

  onInput(change: MatSliderChange): void {
    this.value = change.value || 0;
    this.updateFormValue();
    this.changed.emit(true);
  }
}
