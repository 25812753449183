import { Injectable } from '@angular/core';
import { TemplateProposalContent } from '@app/core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageService } from '../../image/image.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalTemplateService {
  private proposalTemplateSubject = new BehaviorSubject<TemplateProposalContent>({
    customrecord_templateproposal_txtsection: [],
    custrecord_asset_footer: '',
    custrecord_asset_header: '',
    custrecord_asset_logo: '',
    custrecord_introtxt_printout: '',
    custrecord_introtxt_sms: '',
    custrecord_practice_notes: '',
    id: ''
  });

  constructor(private imageService: ImageService) {}

  initProposalTemplate(proposalTemplate: TemplateProposalContent | undefined): void {
    const _proposalTemplate = proposalTemplate
      ? proposalTemplate
      : this.getProposalConfigPlaceholder();
    this.setProposalTemplate(_proposalTemplate);

    if (!_proposalTemplate.custrecord_asset_header || !_proposalTemplate.custrecord_asset_footer) {
      this.loadDefaultProposalTemplateImages(proposalTemplate);
    }
  }

  setProposalTemplate(template: TemplateProposalContent): void {
    this.proposalTemplateSubject.next(template);
  }

  getProposalTemplate$(): Observable<TemplateProposalContent> {
    return this.proposalTemplateSubject.asObservable();
  }

  getProposalTemplateSnaphot(): TemplateProposalContent {
    return this.proposalTemplateSubject.getValue();
  }

  updateProposalTemplate(newValues: Partial<TemplateProposalContent>): void {
    const currentValue = this.proposalTemplateSubject.getValue();
    this.setProposalTemplate({
      ...currentValue,
      ...newValues
    });
  }

  isDocumentsEmpty(): boolean {
    const docs = this.getProposalTemplateSnaphot().customrecord_templateproposal_txtsection;
    return docs && docs.length > 0 ? false : true;
  }

  private loadDefaultProposalTemplateImages(
    planProposalTemplate: TemplateProposalContent | undefined
  ): void {
    this.imageService.getImageAsBase64().subscribe(data => {
      if (!planProposalTemplate?.custrecord_asset_footer) {
        this.updateProposalTemplate({ custrecord_asset_footer: data });
      }

      if (!planProposalTemplate?.custrecord_asset_header) {
        this.updateProposalTemplate({ custrecord_asset_header: data });
      }
    });
  }

  private getProposalConfigPlaceholder(): TemplateProposalContent {
    return {
      customrecord_templateproposal_txtsection: [],
      custrecord_asset_footer: '',
      custrecord_asset_header: '',
      custrecord_asset_logo: '',
      custrecord_introtxt_printout: '',
      custrecord_introtxt_sms: '',
      custrecord_practice_notes: '',
      id: ''
    };
  }
}
