<div *ngIf="(config$ | async) && (lang$ | async)">
  <ngx-loading-bar
    [includeSpinner]="false"
    height="5px"
    class="ngx-loading-bar"
    [color]="(fullscreenLoaderState$ | async)?.show ? '#fff' : '$primary'"
  >
  </ngx-loading-bar>

  <div class="wrapper" [class]="wrapperClass$ | async" (window:resize)="onResize($event)">
    <router-outlet></router-outlet>
  </div>
</div>

<sliqpay-fullscreen-loader
  *ngIf="(fullscreenLoaderState$ | async)?.show"
></sliqpay-fullscreen-loader>
