import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorResponse, GenericModelResponse } from '../../models';
import { ApiUrlService } from '../../services/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsDataService {
  supportCaseCreationTypeCode = 'TNC_CONTACT_ACC_MGR';

  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  postCreateSupportCaseMaster(): Observable<GenericModelResponse<any, ErrorResponse>> {
    const payload: PostCreateSupportCaseMasterRequest = {
      supportCaseCreationTypeCode: this.supportCaseCreationTypeCode
    };
    return this.httpClient.post<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.createSupportCaseMaster,
      payload
    );
  }

  postApproveTnc(
    fullName: string,
    signature: string
  ): Observable<GenericModelResponse<any, ErrorResponse>> {
    const payload: PostApproveTncRequest = {
      sign_full_name: fullName,
      sign_signature: signature
    };
    return this.httpClient.post<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.approveTnc,
      payload
    );
  }

  getSignedTncPdf(): Observable<GenericModelResponse<any, ErrorResponse>> {
    return this.httpClient.get<GenericModelResponse<any, ErrorResponse>>(
      this.apiUrlService.getSignedTncPdf
    );
  }
}

export interface PostCreateSupportCaseMasterRequest {
  supportCaseCreationTypeCode: string;
}

export interface PostApproveTncRequest {
  sign_full_name: string;
  sign_signature: string;
}
