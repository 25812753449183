import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AppCheckbox } from '@app/shared/interfaces/checkbox.interface';

@Component({
  selector: 'sliqpay-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() config!: AppCheckbox | null;
  @Input() isFormGroup = true; // Default ng-select as form-group
  @Output() checkboxChange = new EventEmitter<boolean>();

  onChange!: (value: any) => void;
  onTouched!: () => void;

  form!: UntypedFormGroup;

  get isChecked(): boolean {
    return this.config?.checked || this.control?.value ? true : false;
  }

  public get disabled(): boolean {
    return this.config?.disabled || this.control?.disabled ? true : false;
  }

  public get isRequiredTrue(): boolean {
    return this.control.control?.hasValidator(Validators.requiredTrue) || false;
  }

  constructor(@Self() @Optional() public control: NgControl, private fb: UntypedFormBuilder) {
    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.form = this.fb.group({
      checkbox: new UntypedFormControl(null)
    });
  }

  writeValue(obj: boolean): void {
    this.form.get('checkbox')?.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCheckboxChange(isChecked: boolean): void {
    let value = false;
    if (this.isFormGroup) {
      value = this.form.get('checkbox')?.value;
      this.onChange(value);
    } else {
      value = isChecked;
    }

    this.checkboxChange.emit(value);
  }
}
