import { Injectable } from '@angular/core';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { SmsJourneyPlan } from '@app/core/models';
import { RpLinkData, UnsubmittedPlan } from '@app/core/models/common/sms-journey.interface';
import { Progress } from '@app/core/models/payment/progress.interface';
import { TreatmentOptionResponse } from '@app/treatment-proposal/models/get-unsubmitted-proposal.response';
import { PaymentFlow } from '@app/treatment-proposal/pages/treatment-option-page/treatment-option-page.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { PendingPlanProgressService } from './progress/pending-plan-progress.service';
import { ProposalTemplateService } from './proposal-template/proposal-template.service';

/**
 * this service is used in sms journey
 * TODO: move to smsjourney/rpjourney module?
 */

@Injectable({
  providedIn: 'root'
})
export class PendingPlanService {
  private pendingPlanBehaviorSubject = new BehaviorSubject<UnsubmittedPlan | null>(null);
  private rpLinkDataSubject = new BehaviorSubject<RpLinkData | null>(null);

  constructor(
    private proposalTemplateService: ProposalTemplateService,
    private planProgressService: PendingPlanProgressService,
    private userSettingService: UserSettingsService
  ) {}

  initPendingPlan(unsubmittedPlan: UnsubmittedPlan): void {
    this.proposalTemplateService.initProposalTemplate(
      unsubmittedPlan.payment_plan.customrecord_template_proposal
    );
    this.planProgressService.setProgress(unsubmittedPlan.payment_plan.plan_progress as Progress[]);
    this.setPendingPlan(unsubmittedPlan);
  }

  setPendingPlan(unsubmittedPlan: UnsubmittedPlan): void {
    const transformed = this.transformUnsubmittedPlan(unsubmittedPlan);
    this.pendingPlanBehaviorSubject.next(transformed);
  }

  getPendingPlan(): UnsubmittedPlan | null {
    return this.pendingPlanBehaviorSubject.getValue();
  }

  getPendingPlan$(): Observable<UnsubmittedPlan | null> {
    return this.pendingPlanBehaviorSubject;
  }

  updatePaymentPlanState(newValue: Partial<SmsJourneyPlan>): void {
    const snapshot = this.getPendingPlan();
    if (snapshot) {
      const newPlan = {
        ...snapshot.payment_plan,
        ...newValue
      };

      this.setPendingPlan({
        ...snapshot,
        payment_plan: newPlan
      });
    }
  }

  setRpLinkData(rpLinkData: RpLinkData): void {
    this.rpLinkDataSubject.next(rpLinkData);

    const pendingPlan = this.getPendingPlan();
    if (pendingPlan) {
      sessionStorage.setItem(`pv.${pendingPlan.payment_plan.prev_ddr}`, JSON.stringify(rpLinkData));
    }
  }

  getRpLinkData(): RpLinkData | null {
    return this.rpLinkDataSubject.getValue();
  }

  hasPayInFullDetails(): boolean {
    return !!this.getPifTotalAmount();
  }

  isPifPaymentFlow(): boolean {
    const pendingPlan = this.getPendingPlan()?.payment_plan;
    return pendingPlan?.payment_flow === PaymentFlow.PayInFull || false;
  }

  getPifTotalTreatmentCost(): number {
    const pifTotalTreatmentCost = this.getSelectedTreatmentOption()?.pif_total_plan_value;
    if (pifTotalTreatmentCost) {
      return +pifTotalTreatmentCost;
    }
    return 0;
  }

  getPifDiscount(): number {
    const pifDiscount = this.getSelectedTreatmentOption()?.pif_discount_amount;
    if (pifDiscount) {
      return +pifDiscount;
    }
    return 0;
  }

  getPifOtherDiscount(): number {
    const otherDiscount = this.getSelectedTreatmentOption()?.pif_other_discount_amount;
    if (otherDiscount) {
      return +otherDiscount;
    }
    return 0;
  }

  getPifTotalAmount(): number {
    return this.getPifTotalTreatmentCost() - this.getPifDiscount() - this.getPifOtherDiscount();
  }

  isProposalModule(): boolean {
    const pendingPlan = this.getPendingPlan()?.payment_plan;
    return pendingPlan?.custrecord_module_type === ModuleType.Proposal;
  }

  hasPaymentSecured$(): Observable<boolean> {
    return this.getPendingPlan$().pipe(
      map(data => {
        const plan = data?.payment_plan;

        return !!plan?.cc_payment_details;
      })
    );
  }

  getPayDepositTo(): string {
    return this.getSelectedTreatmentOption()?.pay_deposit_to || '';
  }

  getPendingPlanPlanType(): PlanType {
    const pendingPlan = this.getPendingPlan()?.payment_plan;
    return pendingPlan?.plan_type as PlanType;
  }

  getSelectedTreatmentOption(): TreatmentOptionResponse | undefined {
    const pendingPlan = this.getPendingPlan()?.payment_plan;
    return pendingPlan?.treatment_options?.find(option => option.selected);
  }

  private transformUnsubmittedPlan(data: UnsubmittedPlan): UnsubmittedPlan {
    return {
      ...data,
      payment_plan: this.transformPaymentPlan(data.payment_plan)
    };
  }

  private transformPaymentPlan(plan: SmsJourneyPlan): SmsJourneyPlan {
    return {
      ...plan,
      treatment_options: plan.treatment_options
        ? this.transformTreatmentOptions(plan.treatment_options)
        : []
    };
  }

  private transformTreatmentOptions(options: TreatmentOptionResponse[]): TreatmentOptionResponse[] {
    return options.map(option => ({
      ...option,
      $$paymentFlowOptions: this.getPaymentOptions(option)
    }));
  }

  private getPaymentOptions(treatmentOption: TreatmentOptionResponse): PaymentFlow[] {
    const options = [];

    if (treatmentOption.is_payment_plan_selected) {
      options.push(PaymentFlow.Normal);
    }

    if (treatmentOption.is_pif_selected) {
      options.push(PaymentFlow.PayInFull);
    }

    return options;
  }
}
