import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserSegmentService } from '@app/core/services/user-segment/user-segment.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sliqpay-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit, AfterViewInit {
  alertBannerMessage = '';

  hideMenu$ = new BehaviorSubject<boolean>(true);
  windowWidth$ = new BehaviorSubject(1280);

  mainElHeightSubject = new BehaviorSubject(0);

  constructor(private router: Router, private userSegmentService: UserSegmentService) {}

  @HostListener('window:resize')
  onWindowResize(): void {
    this.setMainElHeight();
  }

  ngOnInit(): void {
    this.alertBannerMessage = this.userSegmentService.getProviderBanner();

    this.toggleSidebar();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(data => this.toggleSidebar());
  }

  ngAfterViewInit(): void {
    this.setMainElHeight();
  }

  onResize(event: any) {
    this.windowWidth$.next(event.target.innerWidth);
  }

  private setMainElHeight(): void {
    const bannerHeight = document.querySelector('.sliqpay-alert-banner')?.clientHeight || 0;
    this.mainElHeightSubject.next(window.innerHeight - bannerHeight);
  }

  private toggleSidebar(): void {
    const route = document.location.href;
    this.hideMenu$.next(route.includes('provider-journey'));
  }
}
