import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { AccessItemResponse } from '@core/models/setting';
import { SettingDataService } from '@app/core/data-services/settings/settings-data.service';
import { ToastrService } from 'ngx-toastr';
import { ChangeAccess, SettingsChangeAccessService } from './settings-change-access.service';

@Component({
  selector: 'sliqpay-settings-change-access',
  templateUrl: './settings-change-access.component.html',
  styleUrls: ['./settings-change-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsChangeAccessService]
})
export class SettingsChangeAccessComponent implements OnInit {
  LANG_CONFIG_KEY = 'settings_change_access';

  @Input() access: AccessItemResponse | null = null;
  @Input() accesses: AccessItemResponse[] | null = null;

  form: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);

  // Config
  permissions$!: Observable<ChangeAccess[]>;
  access$!: Observable<ChangeAccess[]>;
  nameConfig$!: Observable<AppInput>;
  emailConfig$!: Observable<AppInput>;
  accessDdConfig$!: Observable<AppDropdown>;
  permissionsDdConfig$!: Observable<AppDropdown>;
  saveBtnConfig$!: Observable<AppButton>;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private settingService: SettingDataService,
    private toastrService: ToastrService,
    private staService: SettingsChangeAccessService
  ) {
    this.form = this.fb.group({
      contactname: [
        {
          value: null,
          disabled: true
        },
        Validators.required
      ],
      email: [
        {
          value: null,
          disabled: true
        },
        Validators.required
      ],
      giveaccess: [false],
      permissions: null
    });
  }

  ngOnInit(): void {
    this.setConfig();
    if (this.access) {
      this.form.patchValue(this.access);
      if (this.access.admin === 'true') {
        this.form.get('permissions')?.setValue('1');
      } else {
        this.form.get('permissions')?.setValue('2');
      }
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    const data = this.accesses?.map(item => {
      if (item.contact === this.access?.contact) {
        return {
          ...item,
          ...this.form.value,
          edited: 'true'
        };
      } else {
        return item;
      }
    });

    const reqPayload = {
      providerID: sessionStorage.getItem('provider'),
      data
    };

    this.loading$.next(true);
    this.settingService.updateProviderAccess(reqPayload).subscribe(res => {
      this.loading$.next(false);
      this.activeModal.close();
      this.toastrService.success(res.data[0].msg);
    });
  }

  private setConfig(): void {
    this.permissions$ = this.staService.getPermissionsConfig();
    this.access$ = this.staService.getAccessConfig();
    this.nameConfig$ = this.staService.getNameConfig();
    this.emailConfig$ = this.staService.getEmailConfig();
    this.accessDdConfig$ = this.staService.getAccessDdConfig();
    this.permissionsDdConfig$ = this.staService.getPermissionsDdConfig();
    this.saveBtnConfig$ = this.staService.getSaveBtnConfig();
  }
}
