import { Injectable } from '@angular/core';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import {
  PostRpAffordabilityRequest,
  PostRpAffordabilityResponse
} from '@app/core/models/payment/rp-affordability.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PaymentService } from '../payment.service';
@Injectable()
export class RpAffordabilityService {
  constructor(private paymentService: PaymentService, private modal: NgbModal) {}

  checkRpAffordabilityV2(requestBody: PostRpAffordabilityRequest): Observable<boolean> {
    return this.paymentService.postCheckRpAffordability(requestBody).pipe(
      switchMap(response => {
        if (response.length === 0 || !response[0].show) {
          // Save plan/Send sms
          return of(true);
        }

        return this.checkIfCreatePlanIsBlocked(response[0]);
      })
    );
  }

  private checkIfCreatePlanIsBlocked(
    postRpEligibilityResponse: PostRpAffordabilityResponse
  ): Observable<boolean> {
    // If blocked is true, show should be true (Always show pop-up)
    return this.showRpEligibilityPopup(
      postRpEligibilityResponse.message,
      postRpEligibilityResponse.blocked
    );
  }

  private showRpEligibilityPopup(message: string, isBlocked: boolean): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });

    const buttons = [];

    if (!isBlocked) {
      buttons.push(
        {
          text: 'Proceed',
          class: 'btn-primary w-170 mr-1',
          value: true
        },
        {
          text: 'Cancel',
          class: 'btn-grey w-170 ml-2',
          value: false
        }
      );
    } else {
      buttons.push({
        text: 'Close',
        class: 'btn-primary w-230 ml-1',
        value: false
      });
    }

    modalRef.componentInstance.data = {
      iconClass: 'icon-type-error',
      title: 'Warning',
      content: message,
      buttons
    };

    return modalRef.closed.pipe(
      switchMap(proceedWithSms => {
        if (proceedWithSms) {
          return of(true);
        }

        return of(false);
      })
    );
  }
}
