<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-7 order-1 order-md-0">
      <h5 [innerHTML]="'tnc' | lang : LANG_CONFIG_KEY | async"></h5>
    </div>
    <div class="col-12 col-md-5 order-0 order-md-1 text-md-right pb-2">
      <div class="download-btn">
        <sliqpay-button
          [config]="downloadBtnConfig$ | async"
          (clicked)="downloadTncPDF()"
        ></sliqpay-button>
      </div>
    </div>
  </div>
  <div class="row">
    <pdf-viewer [src]="(data$ | async)?.pdfInUint8Array" [render-text]="true"></pdf-viewer>
  </div>
</div>
