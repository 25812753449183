import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ArrearsDataService } from '@app/core/data-services/arrears/arrears-data.service';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { GenericListResponse, PaymentMissedItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class MissedPaymentsService {
  missedPaymentsSubject = new BehaviorSubject<PaymentMissedItemResponse[]>([]);

  constructor(
    private paymentPlanDataService: PaymentPlanDataService,
    private helperService: HelperService,
    private arrearsDataService: ArrearsDataService,
    private router: Router
  ) {}

  getAllMissedPayments$(): Observable<PaymentMissedItemResponse[]> {
    return this.missedPaymentsSubject.asObservable();
  }

  getMissedPaymentByDdrId$(id: string): Observable<PaymentMissedItemResponse | null> {
    return this.missedPaymentsSubject.pipe(
      map(list => {
        return list.find(item => item['DDR Internal ID'] === id) || null;
      })
    );
  }

  getMissedPaymentByDdrId(id: string): PaymentMissedItemResponse | undefined {
    return this.missedPaymentsSubject.getValue().find(ddr => ddr['DDR Internal ID'] === id);
  }

  updateMissedPaymentByDdrId(id: string, newValue: Partial<PaymentMissedItemResponse>): void {
    const missedPayments = this.missedPaymentsSubject.getValue();
    const missedPaymentIndex = missedPayments.findIndex(ddr => ddr['DDR Internal ID'] === id);

    if (missedPaymentIndex >= 0) {
      missedPayments[missedPaymentIndex] = { ...missedPayments[missedPaymentIndex], ...newValue };
      this.missedPaymentsSubject.next(missedPayments);
    }
  }

  fetchMissedPayments$(): Observable<GenericListResponse<PaymentMissedItemResponse, any>> {
    return this.paymentPlanDataService.getPaymentPlanMissed$().pipe(
      tap(response => {
        if (this.helperService.checkAPIResponse(response)) {
          this.missedPaymentsSubject.next(response.data);
        }
      })
    );
  }

  sendInvoice$(ddrId: string): Observable<any> {
    return this.arrearsDataService.sendArrearsInvoiceViaSms$(ddrId).pipe(
      tap(() => {
        const ddr = this.getMissedPaymentByDdrId(ddrId);

        if (ddr) {
          const currentSmsCount = +ddr['Invoice SMS Counter'];
          this.updateMissedPaymentByDdrId(ddrId, {
            'Invoice SMS Counter': `${currentSmsCount + 1}`
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('sendInvoice unsuccessful', error);
        return throwError(error);
      })
    );
  }

  escalateMissedPayment$(plan: PaymentMissedItemResponse): Observable<any> {
    const planNo = plan['DDR'];
    const requestBody = {
      staffname: this.helperService.getObjectFieldValueByFieldNameAndFieldValue(
        this.helperService.getObjUser().providers,
        'contactId',
        this.helperService.getObjUser().contact_id,
        'firstName'
      ),
      email: this.helperService.getObjUser().email,
      custevent_pa_related_ddr: planNo,
      title: 'APP :' + planNo + ' Escalation to ACA Collections On Behalf',
      custevent6: '2',
      priority: 2,
      status: '1',
      incomingmessage: 'Message: ' + '',
      custevent_support_prov_type: '5',
      templateOption: 7,
      rpID: plan['RP ID']
    };

    return this.arrearsDataService.escalate$(requestBody);
  }

  gotoMissedPaymentDetailsScreen(ddrID: string, isMissedPayment: boolean) {
    this.router.navigateByUrl(
      `payment-plan-summary/missed-payments/details/${isMissedPayment}/${ddrID}`
    );
  }
}
