import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DepositPayee } from '@app/core/enums/deposit.enum';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { TreatmentType } from '@app/core/enums/treatment-type.enum';
import { ProviderContactDdr } from '@app/core/models/common/form-fields.interface';
import {
  MobileNumber,
  PaymentPlanQuote
} from '@app/core/models/payment/create-plan-request.interface';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { ImageService } from '@app/core/services/image/image.service';
import { ProposalTemplateService } from '@app/core/services/proposal-template/proposal-template.service';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { FpdItemsInternalId } from '@app/shared/components/first-payment-date-proposal/first-payment-date-proposal.component';
import { FirstDebitDateOptions } from '@app/shared/components/first-payment-date-proposal/first-payment-date-proposal.service';
import { PreviewProposalRequestPayload } from '@app/templates/models/preview-proposal-payload.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProposalDataService {
  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private imageService: ImageService,
    private proposalTemplateService: ProposalTemplateService
  ) {}

  postCreateUnsubmittedProposal(
    body: PostCreateProposalPlanPayload
  ): Observable<PostCreateUnsubmittedProposalResponse> {
    return this.httpClient
      .post<{ data: PostCreateUnsubmittedProposalResponse; error: any[]; isError: string }>(
        `${this.apiUrlService.createUnsubmittedProposal}`,
        body
      )
      .pipe(
        map(response => {
          return response?.data;
        })
      );
  }

  sendSms(ddr: string, ddrSource: string): Observable<GetSendSmsResponse> {
    return this.httpClient
      .get<{ data: GetSendSmsResponse; error: any[]; isError: string }>(
        `${this.apiUrlService.smsSavedPlanUrl}/${ddr}?company_brand=${environment.company_brand}&ddr_source=${ddrSource}&custrecord_module_type=${ModuleType.Proposal}`
      )
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }

  previewProposal(payload: PreviewProposalRequestPayload): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlService.previewProposal}`, payload).pipe(
      map(response => {
        return response.base64PdfString;
      })
    );
  }

  getPracticeNotes$(): Observable<string | undefined> {
    return this.proposalTemplateService
      .getProposalTemplate$()
      .pipe(map(x => x?.custrecord_practice_notes));
  }

  private getDefaultProposalProviderLogo$(): Observable<string> {
    return this.imageService.getImageAsBase64(
      `assets/images/${environment.client}/default-proposal-logo.png`
    );
  }
}

export interface PostCreateProposalPlanPayload {
  practice_id: string;
  plan_type: PlanType;
  custrecord_ddrip_proactive: boolean;
  ddr_source: string;
  custrecord_module_type: ModuleType;
  rp_title: string;
  rp_firstname: string;
  rp_lastname: string;
  rp_mobile_num: string;
  rp_mobile_num2: MobileNumber;
  rp_email: string;
  rp_dob: string;
  custrecord_mfa_ddr_copy_rp_fields: boolean;
  practice_patient_id_no: string;
  patient_title: string;
  patient_firstname: string;
  patient_lastname: string;
  custrecord_mfa_ddr_patient_dob: string;
  confim_details_cbx: boolean | null;
  reset_plan_progress: boolean;
  treatment_options: PostCreateProposalPlanTreatmentOption[];
  practice_notes: string;
  introductory_text_sms: string;
  introductory_text_printout: string;
  custrecord_ddrip_old_saved_plan_id: string; // empty if new proposal
  prev_ddr: string;
}

export interface PostCreateProposalPlanTreatmentOption {
  selected: boolean;
  custrecord_tp_treatment_option: string;
  name: string;
  doctor_id: string;
  treatment_type: TreatmentType;
  ortho_items?: string[];
  lbl_ortho_items?: string[];
  total_plan_value: number;
  discount_type: DiscountType;
  discount_amount: number;
  discount_percent: number;
  deposit: number;
  payment_plan_total: number;
  payment_freq: string;
  no_of_payments: number;
  custrecord_mfa_ddr_single_payments: number;
  custrecord_override_term_length: boolean;
  start_date: string;
  first_payment_date_option: FirstDebitDateOptions;
  first_payment_date_input: number;
  first_payment_date_type: FpdItemsInternalId;
  discount_reason: string;
  quote: PaymentPlanQuote[];
  custrecord_pa_overwrite_payment_amount: number;
  custrecord_mfa_ddr_term_length: string;
  treatment_description: string;
  text_section_list: string[];
  pay_deposit_to: DepositPayee;
  is_payment_plan_selected: boolean;
  doctor: ProviderContactDdr | null;

  // payInFull
  pif_total_plan_value?: number;
  pif_discount_amount?: number;
  pif_other_discount_amount?: number;
  pif_discount_type?: DiscountType;
  pif_discount_percent?: number;
  is_pif_selected?: boolean;
  pif_pay_to?: string;
}

export interface PostCreateUnsubmittedProposalResponse {
  ddr: number;
  ddr_plan_type_final: string;
}

export interface GetSendSmsResponse {
  0: GetSendSmsDataResponse;
  key: string;
  resumeUrl: string;
  signedUrl: string;
}

export interface GetSendSmsDataResponse {
  field: string;
  message: string;
}
