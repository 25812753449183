import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { InternalIdNamePair } from '@app/core/models/common/form-fields.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { HelperService } from '@app/core/services/helper.service';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { CurrencyInputConfig, NumberInputConfig } from '@app/shared/interfaces/input.interface';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

export interface DynamicDiscountValue {
  discountAmount: number;
  discountAsPercentage: number;
  discountType: DiscountType;
}

@Component({
  selector: 'sliqpay-dynamic-discount',
  templateUrl: './dynamic-discount.component.html',
  styleUrls: ['./dynamic-discount.component.scss']
})
export class DynamicDiscountComponent implements OnInit, OnDestroy, OnChanges {
  @Input() amountToDiscount = 0;
  @Input() discountData: DynamicDiscountValue = {
    discountAmount: 0,
    discountAsPercentage: 0,
    discountType: DiscountType.Currency
  };
  @Output() discountDataChange = new EventEmitter<DynamicDiscountValue>();
  @Input() disableFields = false;

  discountTypeOptions: InternalIdNamePair[] = [];

  COMPONENT_KEY = 'dynamic_discount';
  DiscountType = DiscountType;

  discountValueInputConfig$!: Observable<NumberInputConfig>;
  discountValueCurrencyInputConfig$!: Observable<CurrencyInputConfig>;
  discountTypeDropdownConfig$!: Observable<AppDropdown>;
  destroyed$ = new Subject();

  form!: FormGroup;

  constructor(
    private configService: ConfigService,
    private formFieldsService: FormFieldsService,
    private fb: FormBuilder,
    private helperService: HelperService
  ) {}

  get selectedType(): AbstractControl | null {
    return this.form.get('discountType');
  }

  get discountAmount(): AbstractControl | null {
    return this.form.get('discountAmount');
  }

  get discountAsPercentage(): AbstractControl | null {
    return this.form.get('discountAsPercentage');
  }

  ngOnInit(): void {
    this.setForm();
    this.setUiConfigs();

    this.discountTypeOptions = this.formFieldsService.getDiscountItems();

    this.form.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(formValue => {
        this.discountDataChange.emit(formValue);
      });

    this.setFieldState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.discountData && !changes.discountData.isFirstChange()) {
      this.form.patchValue({
        discountAmount: this.discountData.discountAmount,
        discountAsPercentage: this.discountData.discountAsPercentage,
        discountType: this.discountData.discountType || DiscountType.Currency
      });
    }

    if (
      changes.amountToDiscount &&
      !changes.amountToDiscount.isFirstChange() &&
      this.discountData.discountType === DiscountType.Percentage
    ) {
      this.calculateDiscount();
    }

    if (changes.disableFields && !changes.disableFields.isFirstChange()) {
      this.setFieldState();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  typeChanged(type: DiscountType): void {
    this.selectedType?.setValue(type);
    this.discountAsPercentage?.setValue(0);
    this.discountAmount?.setValue(0);
  }

  calculateDiscount(): void {
    this.discountAmount?.setValue(
      this.getDiscountAmount(this.amountToDiscount, this.discountAsPercentage?.value || 0)
    );
  }

  private getDiscountAmount(ttc: number, percentage: number): number {
    const discountedPrice = ttc * (parseFloat(percentage.toString()) / 100);
    return this.helperService.roundUpToTwoDecimal(discountedPrice);
  }

  private setUiConfigs(): void {
    this.discountValueInputConfig$ = this.configService.getConfigValue(
      this.COMPONENT_KEY,
      'discount_value_input'
    );
    this.discountValueCurrencyInputConfig$ = this.configService.getConfigValue(
      this.COMPONENT_KEY,
      'discount_currency_input'
    );
    this.discountTypeDropdownConfig$ = this.configService.getConfigValue(
      this.COMPONENT_KEY,
      'discount_type_dd'
    );
  }

  private setForm(): void {
    this.form = this.fb.group({
      discountAmount: [this.discountData.discountAmount],
      discountAsPercentage: [this.discountData.discountAsPercentage],
      discountType: [this.discountData.discountType]
    });
  }

  private setFieldState(): void {
    if (this.disableFields) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
  }
}
