import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth/auth.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { CreateNewPasswordService } from './create-new-password.service';

@Component({
  selector: 'sliqpay-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewPasswordComponent implements OnInit {
  LANG_CONFIG_KEY = 'create_new_password';

  form: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  errors$ = new BehaviorSubject(null);

  // Field config
  oldPasswordConfig$!: Observable<AppInput>;
  passwordConfig$!: Observable<AppInput>;
  passwordConfirmationConfig$!: Observable<AppInput>;
  confirmBtnConfig$!: Observable<AppButton>;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private createNewPasswordService: CreateNewPasswordService
  ) {
    this.form = this.fb.group(
      {
        old_password: [null, Validators.required],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required]
      },
      {
        validators: this.passwordValidator
      }
    );
  }

  ngOnInit(): void {
    this.setConfig();
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password')?.value;
    const passwordConfirmation = control.get('password_confirmation')?.value;

    return password && passwordConfirmation && password !== passwordConfirmation
      ? { invalid: 'Password and confirmation password must be the same' }
      : null;
  }

  changePassword(): void {
    if (!this.form.valid) {
      return;
    }
    this.loading$.next(true);

    this.authService
      .changePassword(this.form.value)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loading$.next(false);
          this.errors$.next(error.error.errors);
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.activeModal.close();
        sessionStorage.clear();
      });
  }

  private setConfig(): void {
    this.oldPasswordConfig$ = this.createNewPasswordService.getOldPasswordConfig();
    this.passwordConfig$ = this.createNewPasswordService.getPasswordConfig();
    this.passwordConfirmationConfig$ =
      this.createNewPasswordService.getPasswordConfirmationConfig();
    this.confirmBtnConfig$ = this.createNewPasswordService.getConfirmBtnConfig();
  }
}
