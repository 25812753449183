<div class="modal-header text-center">
  <h4 class="modal-title" [innerHTML]="'title' | lang : LANG_CONFIG_KEY | async"></h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <i class="uil uil-times" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <sliqpay-input
            formControlName="old_password"
            [config]="oldPasswordConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="password"
            [config]="passwordConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <sliqpay-input
            formControlName="password_confirmation"
            [config]="passwordConfirmationConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-12">
          <div *ngIf="form.errors" class="is-invalid">{{ form.errors.invalid }}</div>
          <ng-template [ngIf]="errors$ | async">
            <div class="is-invalid" *ngFor="let err of errors$ | async">
              {{ err?.message }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="container">
    <div class="row">
      <div class="col-12 text-right">
        <sliqpay-button
          [config]="confirmBtnConfig$ | async"
          [loading]="loading$ | async"
          [disabled]="!form.valid"
          (clicked)="changePassword()"
        ></sliqpay-button>
      </div>
    </div>
  </div>
</div>
