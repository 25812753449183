<sliqpay-header
  [pageTitle]="'page_title' | lang : LANG_CONFIG_KEY | async"
  [navigateBackTo]="navigationBackRoute()"
>
  <div class="d-flex flex-column align-items-center">
    <div class="action-buttons">
      <sliqpay-button
        *ngIf="showSendInvoice()"
        [config]="sendInvoiceBtnConfig$ | async"
        (clicked)="sendInvoice()"
      ></sliqpay-button>

      <sliqpay-button
        *ngIf="plan && plan['Over 60 Days'] === '1' && !plan['Escalate to Collection Agency']"
        [config]="escalateBtnConfig$ | async"
        (clicked)="escalate()"
      ></sliqpay-button>
    </div>
  </div>
</sliqpay-header>

<div class="rounded main-content" *ngIf="mpDetails$ | async">
  <div class="content px-0 mb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <h6 class="pl-0">{{ mpPlanDetails.rpName }}</h6>
          <h6 class="pl-0 mb-0">Payment Plan Number - {{ this.ddrID }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="content px-0 pt-0">
    <div class="container-fluid">
      <div class="row">
        <div class="px-0 pt-2 col-md-6 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                <span
                  class="sorting"
                  [innerHTML]="'rp_details' | lang : LANG_CONFIG_KEY | async"
                ></span>
              </h5>
              <label class="card-text mb-0"
                ><span
                  class="sorting"
                  [innerHTML]="'rp_name' | lang : LANG_CONFIG_KEY | async"
                ></span
                >: {{ mpPlanDetails.rpName }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span
                  class="sorting"
                  [innerHTML]="'mobile_num' | lang : LANG_CONFIG_KEY | async"
                ></span
                >: {{ mpPlanDetails.rpMobile }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'email' | lang : LANG_CONFIG_KEY | async"></span
                >: {{ mpPlanDetails.rpEmail }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'dob' | lang : LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.rpDob }}</label
              >
              <br />
              <br />
              <hr />
              <h5 class="card-title">
                <span
                  class="sorting"
                  [innerHTML]="'patient_details' | lang : LANG_CONFIG_KEY | async"
                ></span>
              </h5>
              <label class="card-text mb-0"
                ><span
                  class="sorting"
                  [innerHTML]="'rp_name' | lang : LANG_CONFIG_KEY | async"
                ></span
                >: {{ mpPlanDetails.patientName }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span
                  class="sorting"
                  [innerHTML]="'patient_id_num' | lang : LANG_CONFIG_KEY | async"
                ></span
                >: {{ mpPlanDetails.patientIdNo }}</label
              >
              <br />
              <label class="card-text"
                ><span class="sorting" [innerHTML]="'dob' | lang : LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.patientDob }}</label
              >
              <br />
            </div>
          </div>
        </div>

        <div class="pr-0 pt-2 col-md-6 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                <span
                  class="sorting"
                  [innerHTML]="'missed_payment_activity' | lang : LANG_CONFIG_KEY | async"
                ></span>
              </h5>

              <div class="row">
                <div class="pr-0 col-6">
                  <div class="card h-100">
                    <div class="card-body cardBody">
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'total_outstanding_amount' | lang : LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <h6
                        [ngClass]="{ 'card-text': true, 'text-danger': showRedTotalOverDueAmount }"
                      >
                        <strong>{{ mpPlanDetails.totalOverDueAmount | currency }}</strong>
                      </h6>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="
                            'total_plan_outstanding_amount' | lang : LANG_CONFIG_KEY | async
                          "
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{
                        mpPlanDetails.totalArrearsAmount | currency
                      }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'total_outstanding_fees' | lang : LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{
                        mpPlanDetails.totalOverDueFee | currency
                      }}</strong>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100">
                    <div class="card-body">
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'current_missed_payments' | lang : LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <h6
                        [ngClass]="{
                          'card-text': true,
                          'text-danger': showRedCurrentMissedPayments
                        }"
                      >
                        <strong>{{ mpPlanDetails.currentMissedPayments }}</strong>
                      </h6>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'last_missed_payments' | lang : LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.lastMissed }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'last_contact_activity' | lang : LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.lastContact }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white rounded main-content">
    <div class="content">
      <div class="container-fluid">
        <sliqpay-list-search-widget
          [config]="(searchWidgetConfig$ | async) ?? []"
          (searchValue)="onSearchValueChange($event)"
        ></sliqpay-list-search-widget>

        <div class="row" *ngIf="mpDetailsPaymentHistoryFiltered$ | async">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" sliqpaySortable="dueDate" (sort)="onSort($event)" type="date">
                      <span
                        class="sorting"
                        [innerHTML]="'payment_date' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="reschedPaymentDate"
                      (sort)="onSort($event)"
                      type="date"
                    >
                      <span
                        class="sorting"
                        [innerHTML]="'rescheduled_date' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="rpElementType"
                      (sort)="onSort($event)"
                      type="date"
                    >
                      <span
                        class="sorting"
                        [innerHTML]="'payment_type' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th scope="col" sliqpaySortable="scheduledAmount" (sort)="onSort($event)">
                      <span
                        class="sorting"
                        [innerHTML]="'scheduled_amount' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th scope="col" sliqpaySortable="amountPaid" (sort)="onSort($event)">
                      <span
                        class="sorting"
                        [innerHTML]="'paid_amount' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th scope="col" sliqpaySortable="paymentStatus" (sort)="onSort($event)">
                      <span
                        class="sorting"
                        [innerHTML]="'payment_status' | lang : LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let mpDetailsPaymentHistoryItem of (mpDetailsPaymentHistoryFiltered$
                        | async) || []
                        | searchFilter : searchData
                        | paginate : { itemsPerPage: itemPerPage, currentPage: page }
                    "
                  >
                    <td>{{ mpDetailsPaymentHistoryItem.dueDate }}</td>
                    <td>{{ mpDetailsPaymentHistoryItem.reschedPaymentDate }}</td>
                    <td>{{ mpDetailsPaymentHistoryItem.rpElementType }}</td>
                    <td>{{ mpDetailsPaymentHistoryItem.scheduledAmount | currency }}</td>
                    <td>{{ mpDetailsPaymentHistoryItem.amountPaid | currency }}</td>
                    <td>{{ mpDetailsPaymentHistoryItem.paymentStatus }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <sliqpay-empty-screen
          *ngIf="
            (mpDetailsPaymentHistoryFiltered$ | async) === null ||
            (mpDetailsPaymentHistoryFiltered$ | async)?.length === 0
          "
          text="No missed payments details"
        ></sliqpay-empty-screen>
      </div>
    </div>
  </div>

  <sliqpay-pagination
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
    *ngIf="mpDetailsPaymentHistoryFiltered$ | async"
  ></sliqpay-pagination>
</div>
