export enum RpFpdPreference {
  NextDay = '1',
  FirstNextMonth = '2'
}

export enum RelativeFirstPaymentOption {
  Days = '1',
  Months = '2',
  Weeks = '3'
}
