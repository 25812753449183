import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UpdatePlanFieldCodes } from '@app/core/constants/payment-plan.const';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppTextarea } from '@app/shared/interfaces/textarea.interface';
import { SharedModule } from '@app/shared/shared.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export enum CommentPropertyName {
  DDR_PLANS = 'custrecord_mfa_ddr_provider_comments',
  PENDING_PLANS = 'custrecord_unsubmitted_comment'
}

@Component({
  selector: 'sliqpay-comment-modal',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentModalComponent implements OnInit {
  CONFIG_KEY = 'plan_comment';
  private readonly fieldCode = UpdatePlanFieldCodes.COMMENTS;

  @Input() commentValue = null;
  @Input() id = '';
  @Input() propertyName: CommentPropertyName = CommentPropertyName.DDR_PLANS;

  saveBtnConfig$!: Observable<AppButton>;
  commentsTxtAreaConfig!: Observable<AppTextarea>;
  loading$ = new BehaviorSubject(false);

  form = new FormGroup({
    comments: new FormControl(null)
  });

  constructor(
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private paymentPlanDataService: PaymentPlanDataService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.saveBtnConfig$ = this.configService.getConfigValue(this.CONFIG_KEY, 'save_btn');
    this.commentsTxtAreaConfig = this.configService.getConfigValue(
      this.CONFIG_KEY,
      'comments_textarea'
    );
    this.form.get('comments')?.setValue(this.commentValue);
  }

  saveComment(): void {
    if (this.form.invalid) {
      return;
    }

    const payload = {
      fieldCode: this.fieldCode,
      [this.propertyName]: this.form.get('comments')?.getRawValue()
    };

    this.paymentPlanDataService
      .saveComment$(this.id, payload, this.propertyName)
      .pipe(take(1))
      .subscribe(response => {
        this.toastrService.success(response.message, 'Comment');
        this.closeModal(payload);
      });
  }

  closeModal(result?: any): void {
    this.activeModal.close(result);
  }
}
