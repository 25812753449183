import { Injectable } from '@angular/core';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { SmsJourneyStep } from '@app/core/enums/sms-journey-step.enum';
import { TreatmentType } from '@app/core/enums/treatment-type.enum';
import { UnsubmittedPlan } from '@app/core/models';
import { Progress } from '@app/core/models/payment/progress.interface';
import { PostRpAffordabilityRequest } from '@app/core/models/payment/rp-affordability.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { ConfigService } from '@app/core/services/config/config.service';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { HelperService } from '@app/core/services/helper.service';
import { RpAffordabilityService } from '@app/core/services/rp-affordability/rp-affordability.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import {
  AppThreeDotActionMenu,
  MenuItem
} from '@app/shared/interfaces/three-dot-action-menu.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum PendingPlansActions {
  Comment = '1',
  SendSms = '2',
  ResumePlan = '3',
  ViewEditAmendment = '4',
  Delete = '6',
  DownloadAndPrintProposal = '8' // proposal
}

@Injectable()
export class PaymentsUnsubmittedActionMenuService {
  private CONFIG_KEY = 'pending_plans_action_menu';

  constructor(
    private configService: ConfigService,
    private helperService: HelperService,
    private authService: AuthService,
    private userSettingsService: UserSettingsService,
    private formFieldsService: FormFieldsService,
    private rpAffordabilityService: RpAffordabilityService
  ) {}

  getActionsConfig(plan: UnsubmittedPlan | null): Observable<AppThreeDotActionMenu> {
    const config = this.getUiConfigByModuleType(plan?.custrecord_module_type || ModuleType.Plan);
    return config.pipe(
      map(data => {
        if (!plan) {
          return { menuItems: [] };
        }

        const filteredData = data.menuItems.filter((item: MenuItem) => {
          switch (item.value) {
            case PendingPlansActions.SendSms:
              return this.canSendSms(plan);
            case PendingPlansActions.ResumePlan:
              return this.canResumePlan(plan?.custrecord_module_type);
            case PendingPlansActions.ViewEditAmendment:
              return this.canEditAmendment(plan);
            case PendingPlansActions.Delete:
              return this.canDeletePlan();
            case PendingPlansActions.DownloadAndPrintProposal:
              return this.canDownloadAndPrintProposal(plan?.custrecord_module_type);
          }

          return true;
        });

        return { menuItems: filteredData };
      })
    );
  }

  isTreatmentTypeOtho(treatmentType: string): boolean {
    return (
      treatmentType ===
      this.formFieldsService.getTreatmentTypes().find(x => x.value === TreatmentType.ORTHO)?.value
    );
  }

  postCheckRpAffordability(planDataValue: any, isProposal = false): Observable<boolean> {
    return this.rpAffordabilityService.checkRpAffordabilityV2(
      this.mapPostCheckRpAffordability(planDataValue, isProposal)
    );
  }

  private canSendSms(plan: UnsubmittedPlan | null): boolean {
    if (!plan) {
      return false;
    }

    const isFeatureEnabled =
      plan.json_transformed.plan_type === PlanType.NonGuaranteed
        ? this.userSettingsService.isSmsJourneyConnectEnabled()
        : true;

    if (
      plan.json_transformed.custrecord_ddrip_proactive &&
      !this.isStepComplete(SmsJourneyStep.SaveAsDraft, plan.plan_progress)
    ) {
      return false;
    }

    return (
      this.helperService.getObjUser().tier_permissions.custrecord_tier_take_plan_home &&
      isFeatureEnabled
    );
  }

  private canResumePlan(moduleType: string | undefined): boolean {
    // TODO: use enum
    return this.authService.canModifyData() && (moduleType === '1' || moduleType === '3');
  }

  private getUiConfigByModuleType(moduleType: string): Observable<any> {
    if (moduleType === ModuleType.Proposal) {
      return this.configService.getConfigValue(this.CONFIG_KEY, 'proposal_actions');
    }
    return this.configService.getConfigValue(this.CONFIG_KEY, 'actions');
  }

  private canEditAmendment(plan: UnsubmittedPlan): boolean {
    return (
      this.isAmendment(plan.custrecord_module_type) &&
      !this.isStepComplete(SmsJourneyStep.SmsSent, plan.plan_progress)
    );
  }

  private isAmendment(moduleType: string | undefined): boolean {
    return moduleType === '2';
  }

  private canDeletePlan(): boolean {
    return this.authService.canModifyData();
  }

  private canDownloadAndPrintProposal(moduleType: string | undefined): boolean {
    if (moduleType === ModuleType.Proposal) {
      return true;
    }
    return false;
  }

  private isStepComplete(stepId: SmsJourneyStep, progress: Progress[]): boolean {
    const step = progress.find(_step => _step.custrecord_spps_step_id === stepId);
    return step?.custrecord_spps_completed || false;
  }

  private mapPostCheckRpAffordability(
    planDataValue: any,
    isProposal = false
  ): PostRpAffordabilityRequest {
    const isPatientIsRp = planDataValue.patientDetails.isPatientIsRp;
    let paymentPlanDetails = null;
    let planType = '';

    if (isProposal) {
      // Search for the lowest treatment option
      const treatmentOptions = planDataValue.treatmentOptions as any[];
      const minTreatmentOption = treatmentOptions.reduce((prev, curr) =>
        prev.paymentPlanDetails.paymentPlanTotal < curr.paymentPlanDetails.paymentPlanTotal
          ? prev
          : curr
      );
      paymentPlanDetails = minTreatmentOption.paymentPlanDetails;
      planType = minTreatmentOption.generalDetails.planType;
    } else {
      paymentPlanDetails = planDataValue.paymentPlan.paymentPlanDetails;
      planType = planDataValue.paymentPlan.generalDetails.planType;
    }

    return {
      plan_type: planType,
      payment_plan_total: paymentPlanDetails.paymentPlanTotal,
      quote: [
        {
          term_months: paymentPlanDetails.quote[0].paymentPlanDurationInMonths,
          weekly: paymentPlanDetails.quote[0].weekly,
          fortnightly: paymentPlanDetails.quote[0].fortnightly,
          monthly: paymentPlanDetails.quote[0].monthly
        }
      ],
      rp_firstname: isPatientIsRp
        ? planDataValue.patientDetails.firstName
        : planDataValue.rpDetails.firstName,
      rp_lastname: isPatientIsRp
        ? planDataValue.patientDetails.lastName
        : planDataValue.rpDetails.lastName,
      rp_dob: isPatientIsRp
        ? this.helperService.formatDateToString(planDataValue.patientDetails.dob)
        : this.helperService.formatDateToString(planDataValue.rpDetails.dob),
      rp_mobile_num: isPatientIsRp
        ? planDataValue.patientDetails.mobilePhone
        : planDataValue.rpDetails.mobilePhone
    };
  }
}
