<div class="pp-toast">
  <div
    class="pp-toast-icon uil"
    [ngClass]="isError ? 'uil-exclamation-triangle' : 'uil-check'"
  ></div>
  <div class="pp-toast-content">
    <p class="pp-toast-title mb-0">{{ title }}</p>
    <p class="pp-toast-message mb-0" [innerHTML]="message"></p>
  </div>
</div>
