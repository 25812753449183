import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { AccessItemResponse } from '@app/core/models/setting/access-response';
import { HelperService } from '@app/core/services/helper.service';
import { SettingDataService } from '@app/core/data-services/settings/settings-data.service';
import { SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { environment } from '@environments/environment';

@Component({
  selector: 'sliqpay-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrls: ['./settings-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsUsersComponent implements OnInit {
  LANG_CONFIG_KEY = 'settings_users';
  tab$ = new BehaviorSubject<string>('users');

  itemPerPage = environment.itemPerPage;
  page = 0;
  searchData: SearchRequest = {};
  settingAccessB: AccessItemResponse[] = [];

  settingAccess$ = new BehaviorSubject<AccessItemResponse[] | null>(null);
  settingAccessFiltered$ = new BehaviorSubject<AccessItemResponse[] | null>(null);
  unsubscribe$ = new Subject<boolean>();

  constructor(
    private settingService: SettingDataService,
    private helperService: HelperService,
    protected activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSettingAccess();

    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(params => !!Object.keys(params).length)
      )
      .subscribe(params => {
        if (params.tab) {
          this.tab$.next(params.tab);
        } else {
          this.tab$.next('users');
        }
      });
  }

  getSettingAccess() {
    this.settingService
      .getSettingAccess()
      .pipe(filter(response => !!response.data.length))
      .subscribe(response => {
        this.settingAccess$.next(response.data);
        this.settingAccessFiltered$.next(response.data);
        this.settingAccessB = response.data;
      });
  }

  searchFilter(event: SearchRequest) {
    this.page = 0;
    this.searchData = event;
  }

  onSort({ column, direction }: any) {
    this.settingAccessFiltered$.next(
      this.helperService.onSort(column, direction, this.settingAccess$?.value)
    );
  }

  edited() {
    this.getSettingAccess();
  }
}
