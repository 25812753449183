<div class="auth-page d-flex">
  <div class="left-sidebar">
    <div class="logo text-center">
      <a href="#">
        <img src="{{ logoPath }}" alt="logo" />
      </a>
    </div>
  </div>
  <section class="widget-login">
    <h3 class="pb-1" [innerHTML]="'h3' | lang : LANG_CONFIG_KEY | async"></h3>
    <form>
      <sliqpay-dropdown
        [config]="providerDdConfig$ | async"
        [items]="practiceList$ | async"
        [isFormGroup]="false"
        (changed)="onSelectProviderChange($event)"
      >
      </sliqpay-dropdown>

      <sliqpay-button
        [config]="continueBtnConfig$ | async"
        [loading]="loading$ | async"
        [disabled]="!selectedProvider"
        (clicked)="selectPractice()"
      ></sliqpay-button>
    </form>
  </section>
</div>
