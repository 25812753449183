import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, concat } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth/auth.service';
import { LogoService } from '@app/core/services/logo/logo.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { ProviderInfo } from '@core/models';
import { environment } from '@environments/environment';
import { ProviderPageService } from './provider-page.service';

@Component({
  selector: 'sliqpay-provider-page',
  templateUrl: './provider-page.component.html',
  styleUrls: ['./provider-page.component.scss', '../login-page/login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProviderPageService]
})
export class ProviderPageComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'provider_page';

  selectedProvider!: string;
  themePatch = environment.client;
  logoPath = '';

  practiceList$ = new BehaviorSubject<ProviderInfo[] | null>(null);
  loading$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<boolean>();

  private GO_TO_HOME = 'goToHome';

  // Config
  continueBtnConfig$!: Observable<AppButton>;
  providerDdConfig$!: Observable<AppDropdown>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private providerPageService: ProviderPageService,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.logoPath = this.logoService.getLogoFilePath('big', false);
    this.setConfig();
    this.practiceList$.next(JSON.parse(sessionStorage.getItem('providers') || ''));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectPractice(): void {
    if (!this.selectedProvider) {
      return;
    }

    this.loading$.next(true);

    concat(
      this.authService.setProvider(this.selectedProvider),
      this.authService.fetchFields().pipe(
        map(() => {
          return this.GO_TO_HOME;
        })
      )
    )
      .pipe(
        finalize(() => this.loading$.next(false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        if (data === this.GO_TO_HOME) {
          this.router.navigate(['/home']);
        }
      });
  }

  onSelectProviderChange(event: string): void {
    this.selectedProvider = event;
    sessionStorage.setItem('provider', event);
  }

  private setConfig(): void {
    this.continueBtnConfig$ = this.providerPageService.getContinueBtnConfig();
    this.providerDdConfig$ = this.providerPageService.getProviderDdConfig();
  }
}
