<sliqpay-header
  class="page-header-sticky"
  [pageTitle]="'page_title' | lang : LANG_CONFIG_KEY | async"
>
  <div class="create-btn-container">
    <sliqpay-create-plan-dropdown
      class="mr-2"
      [moduleType]="ModuleType.Proposal"
      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
    ></sliqpay-create-plan-dropdown>
    <sliqpay-create-plan-dropdown></sliqpay-create-plan-dropdown>
  </div>
</sliqpay-header>

<div class="page-content overflow-hidden">
  <div class="bg-white rounded">
    <sliqpay-payments-menu></sliqpay-payments-menu>

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-8">
            <h6 [innerHTML]="'header' | lang : LANG_CONFIG_KEY | async"></h6>
            <p [innerHTML]="'header_desc' | lang : LANG_CONFIG_KEY | async"></p>
          </div>
        </div>
        <sliqpay-list-search-widget
          [config]="(searchWidgetConfig$ | async) ?? []"
          (searchValue)="searchFilter($event)"
          (dateChange)="onDateFilterChange($event)"
        ></sliqpay-list-search-widget>
        <div class="row" *ngIf="paymentPlanFiltered$ | async; else emptyScreen">
          <div class="col-12">
            <div
              class="table-responsive unsubmitted-plans-table"
              [class.drop-down-opened]="dropdownChanges"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th sliqpaySortable="custrecord_module_type_label" (sort)="onSort($event)">
                      <span class="sorting">Request Type</span>
                    </th>
                    <th
                      sliqpaySortable="json_transformed.practice_patient_id_no"
                      (sort)="onSort($event)"
                    >
                      <span class="sorting">Patient ID</span>
                    </th>
                    <th sliqpaySortable="patient_name" (sort)="onSort($event)">
                      <span class="sorting">Patient Name</span>
                    </th>
                    <th sliqpaySortable="id" (sort)="onSort($event)">
                      <span class="sorting">Plan ID</span>
                    </th>
                    <th sliqpaySortable="created_at" (sort)="onSort($event)" type="dateTime">
                      <span class="sorting">Created Date/Time</span>
                    </th>
                    <th sliqpaySortable="$$startDate" (sort)="onSort($event)" type="date">
                      <span class="sorting">Plan Start Date</span>
                    </th>
                    <th
                      sliqpaySortable="deposit_expiry"
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
                      (sort)="onDepositReleaseDateSort($event)"
                      type="dateTime"
                    >
                      <span class="sorting">Deposit Release Date</span>
                    </th>
                    <th
                      sliqpaySortable="custrecord_ddrip_last_sms_date_time"
                      (sort)="onSort($event)"
                      type="dateTime"
                    >
                      <span class="sorting">Date SMS Sent</span>
                    </th>
                    <th
                      sliqpaySortable="plan_progress_percentage"
                      (sort)="onSort($event)"
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.SMILE_TRACKER"
                    >
                      <span class="sorting">Progress</span>
                    </th>
                    <th sliqpaySortable="custrecord_unsubmitted_comment" (sort)="onSort($event)">
                      <span class="sorting">Comments</span>
                    </th>
                    <th sliqpaySortable="smsCountNumber" (sort)="onSort($event)">
                      <span class="sorting">#Sent</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let paymentPlan of (paymentPlanFiltered$ | async) || []
                        | searchFilter : searchData
                        | dateFilter : dateSearchData
                        | paginate : { itemsPerPage: itemPerPage, currentPage: page };
                      trackBy: trackByFn
                    "
                  >
                    <td>{{ paymentPlan.custrecord_module_type_label }}</td>
                    <td>{{ paymentPlan.json_transformed?.practice_patient_id_no }}</td>
                    <td>
                      {{
                        paymentPlan.patient_name
                          ? paymentPlan.patient_name
                          : paymentPlan.json_transformed.rp_firstname +
                            ' ' +
                            paymentPlan.json_transformed.rp_lastname
                      }}
                    </td>
                    <td>{{ paymentPlan.id }}</td>
                    <td>
                      <ng-container *ngIf="paymentPlan.created_at; else noCreatedDateTime">
                        {{
                          paymentPlan.created_at
                            | parseDateToValid
                            | date : 'dd/MM/yy hh:mm a'
                            | lowercase
                        }}
                      </ng-container>
                      <ng-template #noCreatedDateTime>
                        {{ paymentPlan.created_at }}
                      </ng-template>
                    </td>
                    <td>{{ paymentPlan.$$startDate }}</td>
                    <td
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
                    >
                      {{ paymentPlan.$$depositReleaseDate }}
                    </td>
                    <td>
                      <ng-container
                        *ngIf="paymentPlan.custrecord_ddrip_last_sms_date_time; else noSmsDateTime"
                      >
                        {{
                          paymentPlan.custrecord_ddrip_last_sms_date_time
                            | parseDateToValid
                            | date : 'dd/MM/yy hh:mm a'
                            | lowercase
                        }}
                      </ng-container>
                      <ng-template #noSmsDateTime>
                        {{ paymentPlan.custrecord_ddrip_last_sms_date_time }}
                      </ng-template>
                    </td>
                    <td
                      class="progress-td"
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.SMILE_TRACKER"
                    >
                      <sliqpay-progress-bar
                        [progress]="paymentPlan?.plan_progress"
                      ></sliqpay-progress-bar>
                    </td>
                    <td class="text-ellipsis" (click)="comment(paymentPlan)">
                      <span
                        [innerHTML]="paymentPlan.custrecord_unsubmitted_comment | decodeLineBreak"
                      ></span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-between">
                        {{ paymentPlan.smsCountNumber ? paymentPlan.smsCountNumber : '-' }}

                        <ng-container
                          *ngIf="
                            paymentPlan.custrecord_module_type !== ModuleType.Amendment;
                            else amendmentMenu
                          "
                        >
                          <sliqpay-payments-unsubmitted-action-menu
                            [plan]="paymentPlan"
                            (dropdownChanges)="dropdownChanges = $event"
                          ></sliqpay-payments-unsubmitted-action-menu>
                        </ng-container>

                        <ng-template #amendmentMenu>
                          <sliqpay-amendment-action-menu
                            [amendment]="paymentPlan"
                          ></sliqpay-amendment-action-menu>
                        </ng-template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ng-template #emptyScreen>
          <sliqpay-empty-screen></sliqpay-empty-screen>
        </ng-template>
      </div>
    </div>
  </div>

  <sliqpay-pagination
    *ngIf="paymentPlanFiltered$ | async"
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
  ></sliqpay-pagination>
</div>
