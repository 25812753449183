import { ActionReducerMap } from '@ngrx/store';
import { configReducer } from './config.reducer';
import { langConfigReducer } from './lang-config.reducer';

export interface State {
  config: any;
  langConfig: any;
}

export const reducers: ActionReducerMap<State, any> = {
  config: configReducer,
  langConfig: langConfigReducer
};
