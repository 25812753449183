import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { OrderedTextValuePair } from '@app/core/models/common/form-fields.interface';
import { BnplCalculatorV2Service } from '@app/core/services/bnpl-calculator-v2/bnpl-calculator-v2.service';
import { PlanTypeService } from '@app/core/services/plan-type/plan-type.service';
import { PlusConnectCalculatorService } from '@app/core/services/plus-connect-calculator/plus-connect-calculator.service';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { CurrencyInputConfig } from '@app/shared/interfaces/input.interface';
import { combineLatest, Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { DynamicDiscountValue } from '../dynamic-discount/dynamic-discount.component';
import { PayInFullService } from './pay-in-full.service';

@Component({
  selector: 'sliqpay-pay-in-full',
  templateUrl: './pay-in-full.component.html',
  styleUrls: ['./pay-in-full.component.scss'],
  providers: [PayInFullService, BnplCalculatorV2Service, PlusConnectCalculatorService, CurrencyPipe]
})
export class PayInFullComponent implements OnInit, OnDestroy {
  @Input() isRequired = false;

  LANG_CONFIG_KEY = 'pay_in_full';

  payInFullForm!: UntypedFormGroup;

  totalTreatmentCostConfig$!: Observable<CurrencyInputConfig>;
  discountConfig$!: Observable<CurrencyInputConfig>;
  otherDiscountConfig$!: Observable<CurrencyInputConfig>;
  paymentPlanTotalConfig$!: Observable<CurrencyInputConfig>;
  pifPaymentOptionsDdConfig$!: Observable<AppDropdown>;

  pifPaymentOptions: OrderedTextValuePair[] = [];

  destroyed$ = new Subject();

  pifTotalAmountErrMsg = 'Minimum pay in full total amount is 0.';
  maxPifAmountErrorMsg = '';

  get treatmentDesc(): AbstractControl | null {
    return this.rootFormGroup.control.get('treatment_desc');
  }

  get pifTotalTreatmentCost(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentTotalTreatmentCost');
  }

  get pifDiscount(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentDiscount');
  }

  get pifDiscountType(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentDiscountType');
  }

  get pifDiscountAsPercentage(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentDiscountPercentage');
  }

  get pifOtherDiscount(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentOtherDiscount');
  }

  get pifNetAmount(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentNetTotal');
  }

  get pifPaymentOption(): AbstractControl | null {
    return this.payInFullForm.get('fullPaymentPaymentPreference');
  }

  constructor(
    private payInFullService: PayInFullService,
    private rootFormGroup: FormGroupDirective,
    private bnplCalculatorV2Service: BnplCalculatorV2Service,
    private plusConnectCalculatorService: PlusConnectCalculatorService,
    private planTypeService: PlanTypeService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.setConfig();
    this.initForm();

    this.payInFullForm = this.rootFormGroup.control?.get('payInFull') as FormGroup;
    this.pifPaymentOptions = this.payInFullService.getPifPaymentOptions();
    this.plusConnectCalculatorService.setPlanType(PlanType.PayInFull);

    this.setPifTotalTreatmentCostValidator();

    combineLatest([
      this.pifTotalTreatmentCost?.valueChanges.pipe(startWith(0)),
      this.pifDiscount?.valueChanges.pipe(startWith(0)),
      this.pifOtherDiscount?.valueChanges.pipe(startWith(0))
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.calculateNet());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    this.destroyForm();
  }

  calculateNet(): void {
    const totalTreatmentCost = this.pifTotalTreatmentCost?.value || 0;
    const pifDiscount = this.pifDiscount?.value || 0;
    const pifOtherDiscount = this.pifOtherDiscount?.value || 0;

    this.pifNetAmount?.setValue(totalTreatmentCost - pifDiscount - pifOtherDiscount);
  }

  dynamicDiscountChanged(value: DynamicDiscountValue): void {
    this.pifDiscountType?.setValue(value.discountType);
    this.pifDiscountAsPercentage?.setValue(value.discountAsPercentage);
    this.pifDiscount?.patchValue(value.discountAmount);
  }

  private initForm(): void {
    this.payInFullForm = this.rootFormGroup.control?.get('payInFull') as FormGroup;
    this.payInFullForm
      .get('fullPaymentTotalTreatmentCost')
      ?.setValidators(this.isRequired ? [Validators.required, Validators.min(1)] : []);
    this.payInFullForm
      .get('fullPaymentPaymentPreference')
      ?.setValidators(this.isRequired ? [Validators.required] : []);
  }

  private destroyForm(): void {
    this.payInFullForm.get('fullPaymentTotalTreatmentCost')?.clearValidators();
    this.payInFullForm.get('fullPaymentPaymentPreference')?.clearValidators();

    this.payInFullForm.get('fullPaymentTotalTreatmentCost')?.updateValueAndValidity();
    this.payInFullForm.get('fullPaymentPaymentPreference')?.updateValueAndValidity();
  }

  private setConfig(): void {
    this.totalTreatmentCostConfig$ = this.payInFullService.getTotalTreatmentCostConfig();
    this.discountConfig$ = this.payInFullService.getDiscountConfig();
    this.otherDiscountConfig$ = this.payInFullService.getOtherDiscountConfig();
    this.paymentPlanTotalConfig$ = this.payInFullService.getPaymentPlanTotalConfig();
    this.pifPaymentOptionsDdConfig$ = this.payInFullService.getPifPaymentOptionsDropdownConfig$();
  }

  private setPifTotalTreatmentCostValidator(): void {
    const planType = this.planTypeService.getCurrentPlanTypeSnapshot();
    const maxPifAmount = this.planTypeService.isPlanTypeBnpl(planType)
      ? this.bnplCalculatorV2Service.getMaxTreatmentCost()
      : this.plusConnectCalculatorService.getMaxTreatmentCost();
    this.pifNetAmount?.setValidators([Validators.min(0), Validators.max(maxPifAmount)]);
    this.maxPifAmountErrorMsg = `Maximum pay in full total amount is ${this.currencyPipe.transform(
      maxPifAmount
    )}`;
  }
}
