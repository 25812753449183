<!-- KEBAB -->
<button *ngIf="isRootNode" class="btn pr-0" [matMenuTriggerFor]="rootMenu.menu" mat-button>
  <i class="uil uil-ellipsis-v" style="font-size: 16px"></i>
</button>

<sliqpay-menu-panel
  #rootMenu
  [items]="config?.menuItems || []"
  (itemClicked)="onClick($event)"
></sliqpay-menu-panel>
