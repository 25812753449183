<div class="modal-header d-block">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <div>
    <h4
      [innerHTML]="
        'header' | lang : LANG_CONFIG_KEY : [ddrData.custrecord_mfa_responsible_party] | async
      "
      class="amendment-form-heading"
    ></h4>
  </div>
</div>
<div class="modal-container py-0">
  <form [formGroup]="supportAmendmentForm" class="form-group mb-0">
    <section class="amendment-form-section">
      <h5 [innerHTML]="'plan_details' | lang : LANG_CONFIG_KEY | async"></h5>
      <div class="row">
        <div class="col-12 col-md-4 mb-3">
          <label [innerHTML]="'related_plan' | lang : LANG_CONFIG_KEY | async"></label>
          <span *ngIf="ddrData.ddrId" class="amendment-read-only-field-value">{{
            'DDR-' + ddrData.ddrId
          }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label [innerHTML]="'responsible_party_name' | lang : LANG_CONFIG_KEY | async"></label>
          <span class="amendment-read-only-field-value">{{
            ddrData.custrecord_mfa_responsible_party
          }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label [innerHTML]="'patient_name' | lang : LANG_CONFIG_KEY | async"></label>
          <span class="amendment-read-only-field-value">{{ ddrData.custrecord_mfa_patient }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label
            [innerHTML]="'original_payment_plan_amount' | lang : LANG_CONFIG_KEY | async"
          ></label>
          <span class="amendment-read-only-field-value">{{
            ddrData.custrecord_mfa_amount | currency
          }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <div class="position-relative">
            <label [innerHTML]="'remaining_plan_amount' | lang : LANG_CONFIG_KEY | async"></label>
            <i
              class="uil uil-info-circle field-name-tooltip"
              placement="top"
              ngbTooltip="(excl. overdue balance)"
            ></i>
          </div>

          <span class="amendment-read-only-field-value">{{
            ddrData.custrecord_mfa_residual | currency
          }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label
            [innerHTML]="'current_direct_debit_amount' | lang : LANG_CONFIG_KEY | async"
          ></label>
          <span class="amendment-read-only-field-value">{{
            ddrData.custrecord_mfa_ddr_single_payments | currency
          }}</span>
        </div>
        <div class="col-12 col-md-4 mb-3 mb-md-0">
          <label [innerHTML]="'frequency' | lang : LANG_CONFIG_KEY | async"></label>
          <span class="amendment-read-only-field-value">{{
            ddrData.custrecord_mfa_ddr_payment_frequency[0].text
          }}</span>
        </div>
        <div class="col-12 col-md-4">
          <label [innerHTML]="'remaining_duration' | lang : LANG_CONFIG_KEY | async"></label>
          <span class="amendment-read-only-field-value">{{
            ddrData.numOfPayment + ' ' + remainingDurationDisplayText
          }}</span>
        </div>
      </div>
    </section>
    <section class="amendment-form-section">
      <h5 [innerHTML]="'amendment_calculation' | lang : LANG_CONFIG_KEY | async"></h5>
      <div class="row">
        <div
          class="col-12"
          [ngClass]="
            isOrthoTreatmentType && selectedReason?.id === amendmentReasons.NewTreatment
              ? 'col-md-5'
              : 'col-md-8'
          "
        >
          <sliqpay-dropdown
            formControlName="reason"
            [items]="reasons$ | async"
            [config]="reasonDdConfig$ | async"
            [setDefaultIfOneItem]="false"
            (changed)="onAmendmentReasonChange($event)"
          >
          </sliqpay-dropdown>
        </div>

        <div
          class="col-12 col-md-3"
          *ngIf="isOrthoTreatmentType && selectedReason?.id === amendmentReasons.NewTreatment"
        >
          <sliqpay-dropdown
            formControlName="orthoItems"
            [config]="proceduresDdConfig$ | async"
            [items]="procedures"
          >
          </sliqpay-dropdown>
        </div>

        <div class="col-12 col-md-4">
          <sliqpay-input
            [config]="requestorNameConfig$ | async"
            formControlName="requestorName"
          ></sliqpay-input>
        </div>
      </div>
      <div
        class="row align-items-end"
        [ngClass]="{
          'flex-column-reverse flex-md-row-reverse':
            selectedReason?.id === amendmentReasons.FrequencyChange
        }"
      >
        <div
          class="col-12"
          [class.col-md-4]="selectedReason?.id === amendmentReasons.FrequencyChange"
        >
          <div class="row">
            <div
              class="col-12 col-md-8 d-flex flex-column flex-md-row"
              *ngIf="selectedReason?.id !== amendmentReasons.FrequencyChange"
            >
              <sliqpay-input
                class="amendment-plan-calculation-input"
                formControlName="remainingPlanAmount"
                [config]="getRemainingPlanAmountDisabled$ | async"
              ></sliqpay-input>

              <div class="amendment-plan-calculation-symbol-container">
                <span class="amendment-plan-calculation-symbol font-weight-bold">{{
                  selectedReason?.symbol
                }}</span>
              </div>

              <sliqpay-number-input
                class="amendment-plan-calculation-input"
                formControlName="planAmountChange"
                [config]="(planAmountChangeConfig$ | async)!"
                (focusOut)="formatAmendmentAmountToCurrency($event)"
              ></sliqpay-number-input>

              <div class="amendment-plan-calculation-symbol-container">
                <span
                  class="amendment-plan-calculation-symbol font-weight-bold"
                  [hidden]="!selectedReason"
                  >=</span
                >
              </div>

              <sliqpay-input
                class="amendment-plan-calculation-input"
                formControlName="total_plan_value"
                [config]="newPlanAmountConfig$ | async"
              ></sliqpay-input>
            </div>
            <div
              class="col-12 col-md-4"
              [class.col-md-12]="selectedReason?.id === amendmentReasons.FrequencyChange"
            >
              <sliqpay-number-input
                class="amendment-plan-calculation-duration-input"
                formControlName="newDuration"
                [value]="planDurationInMonths?.value"
                [config]="(planTermConfig$ | async)!.input"
              ></sliqpay-number-input>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="!isViewAmendment && selectedReason?.id !== amendmentReasons.FrequencyChange"
        >
          <div class="col-12 mt-2">
            <div
              class="invalid-feedback d-block amendment-plan-calculation-error"
              *ngIf="supportAmendmentForm.errors?.minPlanAmount"
            >
              The decreased amended amount cannot be higher than the original plan amount
            </div>
          </div>
        </ng-container>

        <div
          class="col-12"
          [class.col-md-8]="selectedReason?.id === amendmentReasons.FrequencyChange"
        >
          <sliqpay-slider
            formControlName="newDuration"
            class="amendment-plan-calculation-duration-slider w-100"
            [config]="(planTermConfig$ | async)!.slider"
            [value]="planDurationInMonths?.value"
          ></sliqpay-slider>
        </div>
      </div>

      <ng-container *ngIf="!isViewAmendment">
        <div class="row">
          <div class="col-12">
            <div
              class="invalid-feedback d-block amendment-plan-calculation-error"
              *ngIf="planAmountChange?.touched && newPlanAmount?.errors?.max"
            >
              {{
                'max_payment_plan_amt_error_message'
                  | lang : LANG_CONFIG_KEY : [(maxAmendmentPlanAmount | currency) || '']
                  | async
              }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="invalid-feedback d-block amendment-plan-calculation-error"
              *ngIf="supportAmendmentForm.errors?.maxFrequencyRepaymentAmount"
            >
              {{
                'max_repayment_error_message'
                  | lang
                    : LANG_CONFIG_KEY
                    : [
                        helperService.getPaymentFrequencyName(frequency?.value, true),
                        maxRepaymentAmountForFrequency | currency
                      ]
                  | async
              }}
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="col-12 col-md-12">
          <sliqpay-radio-group
            [config]="paymentFrequencyConfig$ | async"
            [options]="(paymentFrequencyOptions$ | async) || []"
            [disabled]="isFrequencyOptionsDisabled"
            formControlName="payment_freq"
          ></sliqpay-radio-group>
        </div>
        <div class="col-12 col-md-4">
          <sliqpay-date-picker
            [config]="startDateDatePickerConfig$ | async"
            formControlName="start_date"
          ></sliqpay-date-picker>
        </div>
      </div>
      <div class="row" *ngIf="showFeeOwnerSelection">
        <div class="col-12 col-md-3">
          <sliqpay-dropdown
            formControlName="amendmentFeeOwner"
            [items]="ddrData.amendmentFeeOwnerList"
            [config]="feeOwnerConfig$ | async"
          ></sliqpay-dropdown>
        </div>
      </div>
    </section>
  </form>
</div>
<div *ngIf="!isViewAmendment" class="modal-footer">
  <div class="row">
    <div class="col-12 text-right">
      <!-- SUBMIT (for cancellations) -->
      <sliqpay-button
        *ngIf="isAmendmentDecrease && +newPlanAmount?.value <= 0; else proceedBtns"
        [config]="submitCancellationRequestBtnConfig$ | async"
        [loading]="loading$ | async"
        (clicked)="onCancelPlan()"
      ></sliqpay-button>

      <ng-template #proceedBtns>
        <!-- PROCEED WITH SMS (increase, decrease, adjustments) -->
        <sliqpay-button
          *ngIf="!data.amendmentData; else updateAmendmentEl"
          [config]="proceedBtnConfig$ | async"
          [loading]="loading$ | async"
          (clicked)="onProceedWithSms()"
        ></sliqpay-button>

        <ng-template #updateAmendmentEl>
          <!-- UPDATE AMENDMENT -->
          <sliqpay-button
            [config]="updateAmendmentBtnConfig$ | async"
            [loading]="loading$ | async"
            (clicked)="updateAmendment()"
          ></sliqpay-button>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
