import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'sliqpay-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  COMPONENT_KEY = 'settings';

  saveChangesBtnConfig$!: Observable<AppButton>;

  hideSaveBtn$ = new BehaviorSubject<boolean>(true);

  constructor(
    private configService: ConfigService,
    private settingsService: SettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initUiConfigs();

    this.toggleSaveButton();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(data => this.toggleSaveButton());
  }

  saveChanges(): void {
    this.settingsService.saveAccountSettingsClicked();
  }

  private initUiConfigs(): void {
    this.saveChangesBtnConfig$ = this.configService.getConfigValue(
      this.COMPONENT_KEY,
      'saved_changes_btn'
    );
  }

  private toggleSaveButton(): void {
    const route = document.location.href;
    this.hideSaveBtn$.next(route.includes('account'));
  }
}
