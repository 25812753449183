<div class="modal-container">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <h4 [innerHTML]="'header' | lang : LANG_CONFIG_KEY | async"></h4>
  <form [formGroup]="form">
    <div class="form-group pt-2 pb-3">
      <input
        class="form-control h-100"
        placeholder="Patient ID"
        formControlName="practice_patient_id_no"
      />
    </div>
    <sliqpay-button
      [config]="saveBtnConfig$ | async"
      [loading]="loading$ | async"
      [disabled]="!form.valid"
      (clicked)="submit()"
    ></sliqpay-button>
  </form>
</div>
