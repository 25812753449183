import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { HelperService } from '@app/core/services/helper.service';
import { TermsAndConditionsDataService } from '@app/core/data-services/terms-and-conditions/terms-and-conditions-data.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class TermsConditionsModalService {
  private CONFIG_KEY = 'terms_conditions';

  private userObj = this.helperService.getObjUser();

  constructor(
    private configService: ConfigService,
    private helperService: HelperService,
    private tncDataService: TermsAndConditionsDataService,
    private userSettingsService: UserSettingsService
  ) {}

  getClearBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'clear_btn');
  }

  getApproveBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'approve_btn');
  }

  getContactBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'contact_btn');
  }

  getNameConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'name');
  }

  getBusinessName(): string {
    return this.userObj?.name;
  }

  createSupportCase(): Observable<any> {
    return this.tncDataService.postCreateSupportCaseMaster();
  }

  approveTnc(fullName: string, signature: string): Observable<any> {
    return this.tncDataService.postApproveTnc(fullName, signature);
  }

  getTermsApprovedSettings(): any | null {
    return this.userSettingsService.getGeneralSettings()?.termsToBeApproved
      ? this.userSettingsService.getGeneralSettings()?.termsToBeApproved
      : null;
  }

  getTncContent(): string {
    return this.getTermsApprovedSettings()?.custrecord_tnc_pdf_content;
  }

  getTncFileName(): string {
    return this.getTermsApprovedSettings()?.name;
  }

  getTncNotifHeader(): string {
    return this.getTermsApprovedSettings()?.custrecord_tnc_notification_header;
  }

  hasSupportCaseAlready(): boolean {
    return this.getTermsApprovedSettings()?.custentity_cr_term_support_case;
  }

  getSupportCaseText(): string {
    return this.getTermsApprovedSettings()?.custentity_cr_term_support_case_text;
  }

  // return true if custrecord_tnc_last_approval_dt is empty
  isTncApprovalDateBeforeToday(): boolean {
    if (!this.isTncApprovalDateEmpty()) {
      const dateNow = new Date();
      dateNow.setHours(0, 0, 0, 0);

      const splitDate =
        this.getTermsApprovedSettings()?.custrecord_tnc_last_approval_dt?.split('/');
      const approvalDate = splitDate[1] + '-' + splitDate[0] + '-' + splitDate[2];
      return new Date(approvalDate) < dateNow;
    }

    return true;
  }

  getSelectedProviderId(): string {
    return this.userObj?.providerId;
  }

  isTncApproved(): boolean {
    return this.getTermsApprovedSettings() === null;
  }

  private isTncApprovalDateEmpty(): boolean {
    return this.getTermsApprovedSettings()?.custrecord_tnc_last_approval_dt === '';
  }
}
