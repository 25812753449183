import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ConfigService } from '@app/core/services/config/config.service';
import { LangService } from '@app/core/services/lang.service';
import { Observable, Observer, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigsResolver {
  constructor(private configService: ConfigService, private langService: LangService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((obs: Observer<any>) => {
      return combineLatest(this.configService.isConfigLoaded(), this.langService.isConfigLoaded())
        .pipe(
          map(data => data.every(isTrue => isTrue)),
          filter(isAllLoaded => isAllLoaded)
        )
        .subscribe(d => {
          obs.next(true);
          obs.complete();
        });
    });
  }
}
