import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { RpLinkData, UnsubmittedPlan } from '@app/core/models/common/sms-journey.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { BasiqService } from '@app/core/services/basiq/basiq.service';
import { LogoService } from '@app/core/services/logo/logo.service';
import { PendingPlanService } from '@app/core/services/pending-plan/pending-plan.service';
import { LoadingScreenResponse } from '@app/treatment-proposal/models/loading-screen.response';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PaymentPlanInitPageService } from './payment-plan-init-page.service';

@Component({
  selector: 'sliqpay-payment-plan-init-page',
  templateUrl: './payment-plan-init-page.component.html',
  styleUrls: ['./payment-plan-init-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaymentPlanInitPageService]
})
export class PaymentPlanInitPageComponent implements OnInit, OnDestroy {
  themePath = environment.client;
  providerName = '';
  logoPath = '';

  private subs = new Subscription();

  loading$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ppipService: PaymentPlanInitPageService,
    private authService: AuthService,
    private pendingPlanService: PendingPlanService,
    private basiqService: BasiqService,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.logoPath = this.logoService.getLogoFilePath('big', false);
    this.setPlanUrlParams();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setPlanUrlParams(): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    sessionStorage.setItem('queryParams', JSON.stringify(queryParams));

    this.providerName = queryParams['providerName']
      ? queryParams['providerName']
      : this.authService.currentUser()?.name;

    if (queryParams['ddrDraftId']) {
      if (queryParams['custrecord_module_type'] === ModuleType.Proposal) {
        this.retrieveProposalLoaderDetails(queryParams['ddrDraftId']).subscribe(() => {
          this.router.navigate(['/treatment-proposal'], {
            queryParams: {
              ddrDraftId: queryParams['ddrDraftId'],
              expires: queryParams['expires'],
              payload_encrypt: queryParams['payload_encrypt'],
              source: queryParams['source'],
              signature: queryParams['signature']
            }
          });
        });
      } else {
        this.retrievePlan(queryParams);
      }
    } else {
      sessionStorage.removeItem('resumePlan');
    }
  }

  private retrievePlan(params: Params): void {
    this.subs.add(
      this.ppipService.retrievePlan(params).subscribe((plan: UnsubmittedPlan) => {
        this.pendingPlanService.initPendingPlan(plan);
        this.pendingPlanService.setRpLinkData(params as RpLinkData);
        this.basiqService.setBasiqUserId(plan.payment_plan.basiq.basiqUserId);

        this.router.navigate(['/payment-sms-journey'], {
          queryParams: {
            ddrDraftId: params['ddrDraftId'],
            expires: params['expires'],
            payload_encrypt: params['payload_encrypt'],
            source: params['source'],
            signature: params['signature']
          }
        });
      })
    );
  }

  private retrieveProposalLoaderDetails(ddrDraftId: string): Observable<LoadingScreenResponse> {
    return this.ppipService.retrieveProposalLoaderDetails(ddrDraftId, this.providerName);
  }
}
