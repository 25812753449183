import { Pipe, PipeTransform } from '@angular/core';
import { DateSearchRequest } from '@app/shared/interfaces/search-request.interface';
import parse from 'date-fns/parse';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {
  transform(value: any[], { from, to, searchColumn }: DateSearchRequest): any[] {
    const splitSearchColumn = searchColumn?.split('.');
    const parseFrom = from ? from.setHours(0, 0, 0, 0) : null;
    const parseTo = to ? to.setHours(23, 59, 59, 59) : null;

    if (parseFrom && parseTo) {
      value = value.filter(item => {
        const filteredItem =
          splitSearchColumn?.length === 2
            ? item[splitSearchColumn[0]][splitSearchColumn[1]]
            : item[searchColumn ? searchColumn : ''];
        const parseValue = parse(filteredItem, 'dd/MM/yyyy', new Date()).getTime();

        if (filteredItem && parseFrom <= parseValue && parseTo >= parseValue) {
          return item;
        }
      });
    } else if (parseFrom) {
      value = value.filter(item => {
        const filteredItem =
          splitSearchColumn?.length === 2
            ? item[splitSearchColumn[0]][splitSearchColumn[1]]
            : item[searchColumn ? searchColumn : ''];
        const parseValue = parse(filteredItem, 'dd/MM/yyyy', new Date()).getTime();

        if (filteredItem && parseFrom <= parseValue) {
          return item;
        }
      });
    } else if (parseTo) {
      value = value.filter(item => {
        const filteredItem =
          splitSearchColumn?.length === 2
            ? item[splitSearchColumn[0]][splitSearchColumn[1]]
            : item[searchColumn ? searchColumn : ''];
        const parseValue = parse(filteredItem, 'dd/MM/yyyy', new Date()).getTime();

        if (filteredItem && parseTo >= parseValue) {
          return item;
        }
      });
    }

    return value;
  }
}
