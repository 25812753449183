<div class="modal-container">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <h4 [innerHTML]="'h4' | lang : CONFIG_KEY | async"></h4>

  <form [formGroup]="form">
    <sliqpay-textarea
      formControlName="comments"
      [config]="commentsTxtAreaConfig | async"
    ></sliqpay-textarea>

    <div class="d-flex justify-content-end">
      <sliqpay-button
        [config]="saveBtnConfig$ | async"
        [loading]="loading$ | async"
        (clicked)="saveComment()"
      ></sliqpay-button>
    </div>
  </form>
</div>
