import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as ConfigActions from '../actions/config.action';

interface AppState {
  config: any;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigSelector {
  constructor(private store: Store<AppState>) {}

  getConfig(): Observable<any> {
    return this.store.select('config');
  }

  setConfig(config: any): void {
    this.store.dispatch(new ConfigActions.UpdateConfig(config));
  }
}
