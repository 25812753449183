<div class="modal-container">
  <div class="close-block">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <h4 [innerHTML]="'header' | lang : LANG_CONFIG_KEY | async"></h4>
  <form [formGroup]="form">
    <div class="widget-login__form">
      <div class="row">
        <div class="col-6">
          <sliqpay-input
            formControlName="contactname"
            [config]="nameConfig$ | async"
          ></sliqpay-input>
        </div>
        <div class="col-6">
          <sliqpay-input formControlName="email" [config]="nameConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <sliqpay-dropdown
            formControlName="giveaccess"
            [config]="accessDdConfig$ | async"
            [items]="access$ | async"
          >
          </sliqpay-dropdown>
        </div>
        <div class="form-group col-6">
          <sliqpay-dropdown
            formControlName="permissions"
            [config]="permissionsDdConfig$ | async"
            [items]="permissions$ | async"
          >
          </sliqpay-dropdown>
        </div>
      </div>
    </div>

    <div class="text-right">
      <sliqpay-button [config]="saveBtnConfig$ | async" (clicked)="submit()"></sliqpay-button>
    </div>
  </form>
</div>
