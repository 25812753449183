<div class="auth-page d-flex">
  <div class="left-sidebar">
    <div class="logo text-center">
      <a href="#">
        <img src="{{ logoPath }}" alt="logo" />
      </a>
    </div>
  </div>
  <section class="widget-login">
    <h3 class="pb-2">
      <span [innerHTML]="'login' | lang : LANG_CONFIG_KEY | async"></span>
    </h3>
    <form [formGroup]="form">
      <div class="widget-login__form">
        <sliqpay-input formControlName="email" [config]="emailConfig$ | async"></sliqpay-input>

        <sliqpay-input
          formControlName="password"
          [config]="passwordConfig$ | async"
        ></sliqpay-input>

        <a routerLink="/login/forgot-password"
          ><span [innerHTML]="'forgot_password' | lang : LANG_CONFIG_KEY | async"></span
        ></a>
      </div>
      <sliqpay-button
        [config]="loginBtnConfig$ | async"
        [loading]="loading$ | async"
        [disabled]="!form.valid"
        (clicked)="login()"
      ></sliqpay-button>
    </form>
  </section>
</div>
