import { Injectable } from '@angular/core';
import { AmendmentDataService } from '@app/core/data-services/amendment/amendment-data.service';
import { AmendmentDdrData } from '@app/core/models/payment/amendment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HelperService } from '../helper.service';
import { catchError, map } from 'rxjs/operators';
import { AmendmentPayload } from '@app/core/models/support/amendment-payload.model';
import { AmendmentReasons } from '@app/core/enums/amendment-reason.enum';

@Injectable({
  providedIn: 'root'
})
export class AmendmentService {
  plan = new BehaviorSubject<AmendmentDdrData | null>(null);

  constructor(
    private amendmentDataService: AmendmentDataService,
    private helperService: HelperService
  ) {}

  setCurrentPlan(plan: AmendmentDdrData): void {
    this.plan.next(plan);
  }

  getCurrentPlan$(): Observable<AmendmentDdrData | null> {
    return this.plan.asObservable();
  }

  // TODO: use refactored calculator in r9
  // code below is from PlusConnectCalculatorService.setTermMonths
  calculateDurationByMonthlyAmount(monthlyAmount: number, planAmount: number): number {
    if (monthlyAmount > 0) {
      const durationInMonths = planAmount / monthlyAmount;
      return Math.ceil(+durationInMonths.toFixed(2));
    }
    return 0;
  }

  // TODO: use refactored calculator in r9
  // code below is from PlusConnectCalculatorService
  getCalculateNoOfPayments(frequency: string, termMonths: number): number {
    const weeksPerYear = 52;

    switch (frequency) {
      case '1': {
        return Math.floor((termMonths / 12) * weeksPerYear);
      }
      case '2': {
        return Math.floor((termMonths / 12) * (weeksPerYear / 2));
      }
      case '3': {
        return termMonths;
      }
      default: {
        return 0;
      }
    }
  }

  getAmendmentDetails(
    ddrId: string,
    rpId: string,
    options?: { checkStatus?: boolean }
  ): Observable<AmendmentDdrData> {
    return this.amendmentDataService.getAmendmentDetails(ddrId, rpId, options).pipe(
      map(res => {
        if (this.helperService.checkAPIResponse(res)) {
          const [data] = this.helperService.getAPIDataResponse(res);
          return data.ddrData;
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  createAmendmentElement(body: AmendmentPayload): Observable<any> {
    return this.amendmentDataService.createAmendmentElement(body);
  }

  createAmendmentAdjustmentRequest(body: AmendmentPayload): Observable<any> {
    return this.amendmentDataService.createAmendmentAdjustmentRequest(body);
  }

  updateAmendmentRequest(pendingPlanId: string, body: AmendmentPayload): Observable<any> {
    return this.amendmentDataService.updateAmendment(pendingPlanId, body);
  }

  getDecreaseAmendmentTypes(): AmendmentReasons[] {
    return [
      AmendmentReasons.CancelTreatment,
      AmendmentReasons.HealthFundPayment,
      AmendmentReasons.ManualPayment
    ];
  }

  acknowledgeAmendmentChanges(draftId: string): Observable<any> {
    return this.amendmentDataService.acknowledgeAmendmentChanges(draftId);
  }
}
